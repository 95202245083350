<template>
  <v-overlay 
    z-index="9999"
    class="text-center" 
    opacity="0.7"
    :value="messages.length > 0 || hasOverlay"
  >
    <v-progress-circular
      v-if="hasOverlay"
      indeterminate
      size="70"
      width="7"
      color="warning" 
    />
    
    <v-alert 
      v-for="message in messages" 
      :key="message.message"
      :type="message.type"
    >
      <span v-html="message.message" />
    </v-alert>  

    <v-btn 
      v-if="messages.length > 0" 
      @click="clearMessages" 
      class="mt-2 mx-auto" 
      fab 
      dark
    >
      <v-icon dark>
        mdi-close
      </v-icon>
    </v-btn>
  </v-overlay>
</template>

<script>
export default {
    computed: {
        hasOverlay(){
            return this.$store.getters['overlay/isEnabled'];
        },
        messages(){
            return this.$store.getters['overlay/getMessages'];
        },
    },
    methods: {
        clearMessages(){
            this.$store.dispatch('overlay/clearMessages');
        }
    }
}
</script> 