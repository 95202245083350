<template>
  <v-card
    outlined
  >
    <v-card-title>
      <v-icon
        class="mr-2"
      >
        mdi-account-box-outline
      </v-icon> 
      {{ titrePage }}
      <v-spacer />
    </v-card-title>

    <v-card-text 
      v-if="loading || !user.id"
    >
      <v-icon 
        color="primary" 
        large
      >
        mdi-loading mdi-spin
      </v-icon>
    </v-card-text>

    <v-card-text 
      class="text-center"
      v-else
    >
      <v-form 
        ref="form" 
        readonly
      >
        <v-tabs
          background-color="primary"
          center-active
          dark
          v-model="tab"
        >
          <v-tab>Informations personnelles</v-tab>
          <v-tab>Informations professionnelles</v-tab>
          <v-tab>RGPD</v-tab>
          <v-tab>Administration</v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-radio-group
                  v-model="user.civilite"
                  row
                >
                  <template v-slot:label>
                    <div>Civilité</div>
                  </template>                  
                  <v-radio
                    label="Monsieur"
                    :value="1"
                  />
                  <v-radio
                    label="Madame"
                    :value="2"
                  />
                </v-radio-group>
              </v-col>

              <v-col
                cols="12"
                md="6"
              >
                <v-radio-group
                  v-model="user.titre"
                  row
                >
                  <template v-slot:label>
                    <div>Titre</div>
                  </template>                  
                  <v-radio
                    label="Docteur"
                    :value="1"
                  />
                  <v-radio
                    label="Professeur"
                    :value="2"
                  />
                </v-radio-group>
              </v-col>

              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  label="Prénom"
                  v-model="user.prenom"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  label="Nom"
                  v-model="user.nom"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-radio-group
                  v-model="user.typeadr"
                  row
                >
                  <template v-slot:label>
                    <div>Type adresse</div>
                  </template>                  
                  <v-radio
                    label="Cabinet"
                    :value="1"
                  />
                  <v-radio
                    label="Hôpital"
                    :value="2"
                  />
                  <v-radio
                    label="Autre"
                    :value="10"
                  />
                </v-radio-group>
              </v-col>
              <v-col
                cols="12"
                md="6"
                v-if="user.typeadr == 10"
              >
                <v-text-field
                  label="Autre type d'adresse"
                  v-model="user.typeadrautre"
                  v-if="user.typeadr == 10"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  label="Adresse L1"
                  v-model="user.adressel1"
                />
                <v-text-field
                  label="Adresse L2"
                  v-model="user.adressel2"
                />
                <v-text-field
                  label="Adresse L3"
                  v-model="user.adressel3"
                />
                <v-text-field
                  label="Adresse L4"
                  v-model="user.adressel4"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  label="Boite postale"
                  v-model="user.bp"
                />
                <v-text-field
                  label="Lieu-dit"
                  v-model="user.lieudit"
                />
                <v-text-field
                  label="Code postal"
                  v-model="user.cp"
                />
                <v-text-field
                  label="Ville"
                  v-model="user.ville"
                />
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item>
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  label="RPPS"
                  v-model="user.rpps"
                />
              </v-col>

              <v-col
                cols="12"
                md="6"
              >
                <v-autocomplete
                  :items="specialites"
                  v-model="user.specialites"
                  label="Spécialité(s) médicale(s)"
                  multiple
                  small-chips
                />
              </v-col>

              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  label="Téléphone"
                  v-model="user.telephone"
                />
              </v-col>

              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  label="Portable"
                  v-model="user.portable"
                />
              </v-col>

              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  label="Télécopie"
                  v-model="user.telecopie"
                />
              </v-col>

              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  label="Email"
                  v-model="user.email"
                />
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item>
            <v-row>
              <v-col
                cols="12"
              >
                <v-switch
                  v-model="user.optout"
                  flat
                  label="Ne souhaite pas être informé(e) des actualités et recevoir les offres exclusives des partenaires de l'Agence CCC"
                />
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item>
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-autocomplete
                  :items="roles"
                  v-model="user.roles"
                  label="Groupe(s)"
                  multiple
                />
              </v-col>
              <v-col
                cols="12"
              >
                <v-switch
                  v-model="user.statut"
                  flat
                  label="Statut"
                />
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </v-form>
    </v-card-text>


    <v-card-actions class="mb-6">
      <v-spacer />
      <v-btn
        color="primary"
        dark
        depressed
        @click="retour"
      >
        <v-icon class="mr-1">
          mdi-chevron-left
        </v-icon>
        Retour
      </v-btn>
      <v-btn
        color="warning"
        depressed
        @click="edit"
        v-if="can('admin_users_edit')"
      >
        <v-icon class="mr-1">
          mdi-pencil
        </v-icon>
        Modifier
      </v-btn>
      <v-spacer />
    </v-card-actions>
  </v-card>
</template>

<script>
import api from './api'

export default {
  data() {
    return {
      tab: 0,
      roles: [],
      specialites: [],
      loading: false,
      user: {},
      listeCivilite: {
        1:'Monsieur',
        2:'Madame'
      },
      listeTitre: {
        1:'Docteur',
        2:'Professeur'
      },
      listeTypeAdresse: {
        1:'Cabinet',
        2:'Hôpital',
        3:'Domicile',
        10:'Autre',
      },
    };
  },
  computed:{
    titrePage(){
      return (this.loading || !this.user.id)?'Chargement en cours...':this.user.prenom + ' ' + this.user.nom;
    },
    civilite(){
      return this.listeCivilite[this.user.civilite];
    },
    titre(){
      return this.listeTitre[this.user.titre];
    },
    typeAdresse(){
      return (this.user.typeadr == 10)?this.user.typeadrautre:this.listeTypeAdresse[this.user.typeadr];
    },
    specialitesUtilisateur(){
      if (this.user.id && this.specialites.length > 0){
        let tmpSpecs = [];
        for (let i=0;i<this.user.specialites.length;i++){
          let spec = this.specialites.find(element => {
            return element.value === this.user.specialites[i];
          })
          tmpSpecs.push(spec.text);
        }
        return tmpSpecs.join(', ')
      }
      return '';
    },
    typeAdr(){
      return this.user.typeadr;
    },
  },
  watch:{
    typeAdr(){
      if (this.typeAdr != 10){
        this.user.typeadrautre = '';
      }
    },
  },
  methods: {

    // Get list of Roles + Specs
    getParams(){

      api.getParams()
      .then( response => {

        if (response.data){
          this.roles = response.data.roles;
          this.specialites = response.data.specialites;
        }

      })
      .catch(error => {
    
        if (error.response) {
          // client received an error response (5xx, 4xx)
          if (error.response.status === 401){
            this.$store.dispatch('auth/logoutUser');
            this.$store.dispatch('overlay/setMessages',[{type:'error',message:'Votre session a expirée.'}]);
          } else {
            this.$store.dispatch('overlay/setMessages',[{type:'error',message:'Une erreur s\'est produite. Merci de réessayer.'}]);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          this.$store.dispatch('overlay/setMessages',[{type:'error',message:'Erreur de connexion. Merci de réessayer.'}]);
        } else {
          // anything else
          this.$store.dispatch('overlay/setMessages',[{type:'error',message:'Une erreur s\'est produite. Merci de réessayer.'}]);
        }

        this.retour();

      });

    },

    // Get User Data
    getUser(id){

      this.loading = true;

      api.getUser(id)
      .then( response => {

        this.loading = false;
        if (response.data.user){
          this.user = response.data.user;
        } else {
          this.retour();
        }

      })
      .catch(error => {

        this.loading = false;
        if (error.response) {
          // client received an error response (5xx, 4xx)
          if (error.response.status === 401){
            this.$store.dispatch('auth/logoutUser');
            this.$store.dispatch('overlay/setMessages',[{type:'error',message:'Votre session a expirée.'}]);
          } else {
            this.$store.dispatch('overlay/setMessages',[{type:'error',message:'Une erreur s\'est produite. Merci de réessayer.'}]);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          this.$store.dispatch('overlay/setMessages',[{type:'error',message:'Erreur de connexion. Merci de réessayer.'}]);
        } else {
          // anything else
          this.$store.dispatch('overlay/setMessages',[{type:'error',message:'Une erreur s\'est produite. Merci de réessayer.'}]);
        }

        this.retour();

      });

    },
    edit(){
      this.$router.push({ name: 'UtilisateurEdit', params: { id: this.user.id } })
    },
    retour(){
      // this.$router.push({ name: 'Utilisateurs' })
      this.$router.go(-1)
    },
  },
  beforeMount(){
    this.getParams();
  },
  mounted(){
    this.getUser(this.$route.params.id);
  },
};

</script>
