import Api      from "@/apis/Api.js";
import Csrf     from "@/apis/Csrf"

export default {
    
  // Get list of roles
  async getRoles(options){
    await Csrf.getCookie();
    return Api.post(`/admin/permissions/index`,options);
  },

  // Save group
  async save(form){
    await Csrf.getCookie();
    if (form.id == null){
      return Api.post(`/admin/permissions/store`,form);
    } else {
      return Api.post(`/admin/permissions/update`,form);
    }
  },

  // Delete group
  async delete(id){
    await Csrf.getCookie();
    return Api.post(`/admin/permissions/delete`,{'id':id});
  },

}