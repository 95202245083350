import Dashboard from './Dashboard.vue'

export const DashboardRoutes = [

    {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard,
        meta: { 
            title: 'Dashboard',
            requiresAuth: true,
        }
    },
        
]