<template>
  <v-card
    outlined
  >
    <v-card-title>
      <v-icon
        class="mr-2"
      >
        mdi-account-box-multiple-outline
      </v-icon> 
      Utilisateurs
      <v-spacer />

      <v-btn icon>
        <v-icon v-if="loading">
          mdi-loading mdi-spin
        </v-icon>
        <v-icon 
          v-else 
          @click="refresh"
        >
          mdi-refresh
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-container fluid>
      <v-row>
        <v-col class="col-6">
          <v-text-field
            v-model="options.search"
            append-icon="mdi-magnify"
            label="Recherche"
            clearable
            hide-details="auto"
          />
        </v-col>
        <v-col class="col-3">
          <v-autocomplete
            :items="statut"
            v-model="options.statut"
            label="Statut"
            clearable
            hide-details="auto"
          />
        </v-col>
        <v-col class="col-3">
          <v-switch
            color="red"
            hide-details
            v-model="options.supprime"
            flat
            inset
            label="Supprimé"
          />
        </v-col>
        <v-col class="col-6">
          <v-autocomplete
            :items="specialites"
            v-model="options.specialites"
            label="Specialité(s)"
            multiple
            clearable
            small-chips
            deletable-chips
            hide-details="auto"
          />
        </v-col>
        <v-col class="col-6">
          <v-autocomplete
            :items="roles"
            v-model="options.roles"
            label="Groupe(s)"
            multiple
            clearable
            small-chips
            deletable-chips
            hide-details="auto"
          />
        </v-col>
      </v-row>
    </v-container>

    <v-data-table
      :page="options.page"
      :page-count="pages"
      :headers="headers"
      :items="users"
      :options.sync="options"
      :server-items-length="total"
      :loading="loading"
      :multi-sort="options.multiSort"
      :must-sort="options.mustSort"
      :items-per-page="options.itemsPerPage"
      :footer-props="{
        'items-per-page-options':[ 5, 10, 20, 50, 100 ]
      }"
    >
      <template v-slot:item="row">
        <tr
          :key="row.item.id"
        >
          <td class="text-left">
            {{ row.item.id }}
          </td>
          <td class="text-left">
            {{ row.item.name }}
          </td>
          <td class="text-left">
            <a :href="'mailto:' + row.item.email">{{ row.item.email }}</a>
          </td>
          <td class="text-left py-2">
            <v-chip
              v-for="role in row.item.roles" 
              :key="role"                
              color="gray"
              text-color="white"
              small
              dark
              class="ma-1"
            >
              {{ role }}
            </v-chip>
          </td>
          <td class="text-right text-no-wrap">
            <v-tooltip 
              top
              v-if="!row.item.deleted"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn 
                  fab 
                  dark 
                  x-small 
                  color="primary"
                  depressed
                  class="mx-1"
                  @click="viewUser(row.item.id)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon dark>
                    mdi-account-box-outline
                  </v-icon>
                </v-btn>
              </template>
              <span>Afficher informations</span>
            </v-tooltip>     
            <v-tooltip 
              top
              v-if="!row.item.deleted"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn 
                  fab 
                  dark 
                  x-small 
                  :color="row.item.verified ? 'grey' : 'warning'"
                  depressed
                  class="mx-1"
                  @click="return row.item.verified ? false : resendVerifyEmail(row.item.id)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon
                    dark
                    v-if="row.item.verified"
                  >
                    mdi-email-check-outline
                  </v-icon>
                  <v-icon 
                    dark
                    v-else
                  >
                    mdi-email-sync-outline
                  </v-icon>
                </v-btn>            
              </template>
              <span>{{ row.item.verified ? 'Adresse email vérifiée' : 'Renvoyer un mail avec le lien de vérification' }}</span>
            </v-tooltip>     
            <v-tooltip 
              top
              v-if="!row.item.deleted && row.item.id != currentUser.id && can('admin_users_edit')"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn 
                  fab 
                  dark 
                  x-small 
                  :color="row.item.statut == 1 ? 'success' : 'warning'"
                  depressed
                  class="mx-1"
                  @click="changeState(row.item.id, 1 - row.item.statut)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon 
                    dark
                    v-if="row.item.statut == 1"
                  >
                    mdi-account-check
                  </v-icon>
                  <v-icon 
                    dark
                    v-else
                  >
                    mdi-account-cancel
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ row.item.statut == 1 ? 'Compte activé' : 'Compte désactivé' }}</span>
            </v-tooltip>     
            <v-tooltip 
              top
              v-if="!row.item.deleted && row.item.name != 'Super Admin' && can('admin_users_edit')"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn 
                  fab 
                  dark 
                  x-small 
                  color="warning"
                  depressed
                  class="mx-1"
                  @click="editUser(row.item.id)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon dark>
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </template>
              <span>Modifier les informations</span>
            </v-tooltip>     
            <v-tooltip 
              top
              v-if="row.item.deleted && can('admin_users_edit')"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn 
                  fab 
                  dark 
                  x-small 
                  color="success"
                  depressed
                  class="mx-1"
                  @click="restoreUser(row.item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon dark>
                    mdi-delete-restore
                  </v-icon>
                </v-btn>
              </template>
              <span>Restaurer ce compte</span>
            </v-tooltip>  
            <v-tooltip 
              top
              v-if="row.item.name != 'Super Admin' && can('admin_users_delete') && row.item.id != currentUser.id"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn 
                  fab 
                  dark 
                  x-small 
                  color="error"
                  depressed
                  class="mx-1"
                  @click="deleteUser(row.item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon dark>
                    mdi-trash-can-outline
                  </v-icon>
                </v-btn>
              </template>
              <span>Supprimer ce compte</span>
            </v-tooltip>  
          </td>
        </tr>
      </template>      
    </v-data-table>

    <v-card-actions
      class="pa-5"
    >
      <v-spacer />
      <v-btn
        color="primary"
        dark
        depressed
        v-if="can('admin_users_add')"
        @click="addUser"
      >
        <v-icon class="mr-1">
          mdi-plus
        </v-icon>
        Ajouter un utilisateur
      </v-btn>
      <v-spacer />
    </v-card-actions>
  </v-card>
</template>

<script>
import api from './api'

export default {
  data() {
    return {
      total: 0,
      pages: 0,
      statut: [
        { text: "Publié", value: 1 },
        { text: "Dépublié", value: 0 },
      ],
      users: [],
      roles: [],
      specialites: [],
      loading: false,
      options: {},
      headers: [
        { text: "#", value: "id", sortable:true },
        { text: "Nom", value: "prenom", sortable:true },
        { text: "Email", value: "email", sortable:true },
        { text: "Groupes", value: "roles", sortable:false },
        { text: "", value: null, sortable: false },
      ],
    };
  },
  watch: {
    options: {
      handler(){
        this.getUsers();
      },
      deep: true
    }
  },
  methods: {
    // Get list of Roles + Specs
    getParams(){

      api.getParams()
      .then( response => {

        if (response.data){
          this.roles = response.data.roles;
          this.specialites = response.data.specialites;
        }

      })
      .catch(error => {

        if (error.response) {
          // client received an error response (5xx, 4xx)
          if (error.response.status === 401){
            this.$store.dispatch('auth/logoutUser');
            this.$store.dispatch('overlay/setMessages',[{type:'error',message:'Votre session a expirée.'}]);
          } else {
            this.$store.dispatch('overlay/setMessages',[{type:'error',message:'Une erreur s\'est produite. Merci de réessayer.'}]);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          this.$store.dispatch('overlay/setMessages',[{type:'error',message:'Erreur de connexion. Merci de réessayer.'}]);
        } else {
          // anything else
          this.$store.dispatch('overlay/setMessages',[{type:'error',message:'Une erreur s\'est produite. Merci de réessayer.'}]);
        }

      });

    },
    refresh(){
      // this.$store.dispatch('utilisateurs/init');
      // this.options = this.$store.getters['utilisateurs/getOptions'];
      this.getUsers();
    },
    // Get list of Users
    getUsers(){

      this.$store.dispatch('utilisateurs/setOptions',this.options);
      this.loading = true;

      api.getUsers(this.options)
      .then( response => {

        this.loading = false;
        if (response.data){
          this.users = response.data.users;
          this.pages = response.data.pages;
          this.total = response.data.total;
        }

      })
      .catch(error => {

        this.loading = false;
        if (error.response) {
          // client received an error response (5xx, 4xx)
          if (error.response.status === 401){
            this.$store.dispatch('auth/logoutUser');
            this.$store.dispatch('overlay/setMessages',[{type:'error',message:'Votre session a expirée.'}]);
          } else {
            this.$store.dispatch('overlay/setMessages',[{type:'error',message:'Une erreur s\'est produite. Merci de réessayer.'}]);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          this.$store.dispatch('overlay/setMessages',[{type:'error',message:'Erreur de connexion. Merci de réessayer.'}]);
        } else {
          // anything else
          this.$store.dispatch('overlay/setMessages',[{type:'error',message:'Une erreur s\'est produite. Merci de réessayer.'}]);
        }

      });

    },
    addUser(){
      this.$router.push({ name: 'UtilisateursAdd' })
    },
    viewUser(id){
      this.$router.push({ name: 'UtilisateurView', params: { id: id } })
    },
    editUser(id){
      this.$router.push({ name: 'UtilisateurEdit', params: { id: id } })
    },
    restoreUser(item){
      this.$confirm('Restaurer l\'utilisateur "' + item.name + '" ?',{
        buttonTrueText: 'Restaurer',
        buttonFalseText: 'Annuler',
        buttonTrueColor: 'success',
        buttonFalseColor: 'default',
        buttonTrueFlat: true,
        buttonFalseFlat: true,
        color: 'warning',
        icon: 'warning',
        title: 'Attention !',
      }).then(res => {
        if (res){

          this.$store.dispatch('overlay/enable');
          api.restore(item.id)
          .then( () => {
            this.getUsers();
            this.$store.dispatch('overlay/disable');
            this.$store.dispatch('overlay/setMessages',[{type:'success',message:'Utilisateur restauré avec succès.'}]);
          })
          .catch(error => {
            this.$store.dispatch('overlay/disable');
            if (error.response) {
              if (error.response.status === 422){
                this.$store.dispatch('overlay/setMessages',[{type:'warning',message:error.response.data.messages.join('<br>')}]);
              } else if (error.response.status === 401){
                // client received an error response (5xx, 4xx)
                this.$store.dispatch('auth/logoutUser');
                this.$store.dispatch('overlay/setMessages',[{type:'error',message:'Votre session a expirée.'}]);
              } else {
                this.$store.dispatch('overlay/setMessages',[{type:'error',message:'Une erreur s\'est produite. Merci de réessayer.'}]);
              }
            } else if (error.request) {
              // client never received a response, or request never left
              this.$store.dispatch('overlay/setMessages',[{type:'error',message:'Erreur de connexion. Merci de réessayer.'}]);
            } else {
              // anything else
              this.$store.dispatch('overlay/setMessages',[{type:'error',message:'Une erreur s\'est produite. Merci de réessayer.'}]);
            }
          });

        }
      })
    },
    deleteUser(item){
      this.$confirm('Supprimer l\'utilisateur "' + item.name + '" ?',{
        buttonTrueText: 'Supprimer',
        buttonFalseText: 'Annuler',
        buttonTrueColor: 'error',
        buttonFalseColor: 'default',
        buttonTrueFlat: true,
        buttonFalseFlat: true,
        color: 'warning',
        icon: 'warning',
        title: 'Attention !',
      }).then(res => {
        if (res){

          this.$store.dispatch('overlay/enable');
          api.delete(item.id, item.deleted)
          .then( () => {
            this.getUsers();
            this.$store.dispatch('overlay/disable');
            // this.$store.dispatch('overlay/setMessages',[{type:'success',message:'Utilisateur supprimé avec succès.'}]);
          })
          .catch(error => {
            this.$store.dispatch('overlay/disable');
            if (error.response) {
              if (error.response.status === 422){
                this.$store.dispatch('overlay/setMessages',[{type:'warning',message:error.response.data.messages.join('<br>')}]);
              } else if (error.response.status === 401){
                // client received an error response (5xx, 4xx)
                this.$store.dispatch('auth/logoutUser');
                this.$store.dispatch('overlay/setMessages',[{type:'error',message:'Votre session a expirée.'}]);
              } else {
                this.$store.dispatch('overlay/setMessages',[{type:'error',message:'Une erreur s\'est produite. Merci de réessayer.'}]);
              }
            } else if (error.request) {
              // client never received a response, or request never left
              this.$store.dispatch('overlay/setMessages',[{type:'error',message:'Erreur de connexion. Merci de réessayer.'}]);
            } else {
              // anything else
              this.$store.dispatch('overlay/setMessages',[{type:'error',message:'Une erreur s\'est produite. Merci de réessayer.'}]);
            }
          });

        }
      })
    },
    changeState(id,state){
      this.$confirm('Modifier le statut de cet utilisateur ?',{
        buttonTrueText: 'Modifier',
        buttonFalseText: 'Annuler',
        buttonTrueColor: 'error',
        buttonFalseColor: 'default',
        buttonTrueFlat: true,
        buttonFalseFlat: true,
        color: 'warning',
        icon: 'warning',
        title: 'Attention !',
      }).then(res => {
        if (res){
          this.$store.dispatch('overlay/enable');
          api.changeState(id,state)
          .then( () => {
            this.getUsers();
            this.$store.dispatch('overlay/disable');
          })
          .catch(error => {
            this.$store.dispatch('overlay/disable');
            if (error.response) {
              if (error.response.status === 422){
                this.$store.dispatch('overlay/setMessages',[{type:'warning',message:error.response.data.messages.join('<br>')}]);
              } else if (error.response.status === 401){
                // client received an error response (5xx, 4xx)
                this.$store.dispatch('auth/logoutUser');
                this.$store.dispatch('overlay/setMessages',[{type:'error',message:'Votre session a expirée.'}]);
              } else {
                this.$store.dispatch('overlay/setMessages',[{type:'error',message:'Une erreur s\'est produite. Merci de réessayer.'}]);
              }
            } else if (error.request) {
              // client never received a response, or request never left
              this.$store.dispatch('overlay/setMessages',[{type:'error',message:'Erreur de connexion. Merci de réessayer.'}]);
            } else {
              // anything else
              this.$store.dispatch('overlay/setMessages',[{type:'error',message:'Une erreur s\'est produite. Merci de réessayer.'}]);
            }
          });
        }
      })
    },
    resendVerifyEmail(id){
      this.$confirm('Renvoyer un mail avec le lien de vérification ?',{
        buttonTrueText: 'Envoyer',
        buttonFalseText: 'Annuler',
        buttonTrueColor: 'info',
        buttonFalseColor: 'default',
        buttonTrueFlat: true,
        buttonFalseFlat: true,
        color: 'info',
        icon: 'info',
        title: 'Email de vérification',
      }).then(res => {
        if (res){
          this.$store.dispatch('overlay/enable');
          api.sendVerifyLink(id)
          .then( () => {
            this.$store.dispatch('overlay/disable');
            this.$store.dispatch('overlay/setMessages',[{type:'success',message:'Email envoyé avec succès.'}]);
          })
          .catch(error => {
            this.$store.dispatch('overlay/disable');
            if (error.response) {
              if (error.response.status === 422){
                this.$store.dispatch('overlay/setMessages',[{type:'warning',message:error.response.data.messages.join('<br>')}]);
              } else if (error.response.status === 401){
                // client received an error response (5xx, 4xx)
                this.$store.dispatch('auth/logoutUser');
                this.$store.dispatch('overlay/setMessages',[{type:'error',message:'Votre session a expirée.'}]);
              } else {
                this.$store.dispatch('overlay/setMessages',[{type:'error',message:'Une erreur s\'est produite. Merci de réessayer.'}]);
              }
            } else if (error.request) {
              // client never received a response, or request never left
              this.$store.dispatch('overlay/setMessages',[{type:'error',message:'Erreur de connexion. Merci de réessayer.'}]);
            } else {
              // anything else
              this.$store.dispatch('overlay/setMessages',[{type:'error',message:'Une erreur s\'est produite. Merci de réessayer.'}]);
            }
          });
        }
      })
    },
  },
  beforeMount(){
    this.options = this.$store.getters['utilisateurs/getOptions'];
    this.getParams();
  },
  mounted() {
    this.getUsers();
  },
};

</script>
