import Api from "@/apis/Api.js";
import Csrf from "@/apis/Csrf"

export default {

  // Get list of requests
  async getListe(options) {
    await Csrf.getCookie();
    return Api.post(`/admin/inscriptions/index`, options);
  },

  // Delete inscription
  async delete(id) {
    await Csrf.getCookie();
    return Api.post(`/admin/inscriptions/inscription/delete`, { 'id': id });
  },

  // Approuver inscription
  async approve(id, dpcSession) {
    await Csrf.getCookie();
    return Api.post(`/admin/inscriptions/inscription/approve`, { 'id': id, 'dpcsession': dpcSession });
  },

  // Décliner inscription
  async decline(id) {
    await Csrf.getCookie();
    return Api.post(`/admin/inscriptions/inscription/decline`, { 'id': id });
  },

}