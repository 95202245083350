var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-account-box-multiple-outline ")]),_vm._v(" "+_vm._s(_vm.titre)+" "),_c('v-spacer'),(_vm.user)?_c('span',[_vm._v(_vm._s(_vm.user.prenom)+" "+_vm._s(_vm.user.nom))]):_vm._e()],1),(!_vm.showForm)?_c('v-card-text',{staticClass:"text-center"},[_c('v-icon',{attrs:{"color":"primary","large":""}},[_vm._v(" mdi-loading mdi-spin ")])],1):_vm._e(),(_vm.showForm)?_c('v-card-text',{staticClass:"text-center"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticStyle:{"background":"yellow"}},[_c('v-tabs',{attrs:{"background-color":"primary","center-active":"","dark":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_vm._v("Informations personnelles")]),_c('v-tab',[_vm._v("Informations professionnelles")]),_c('v-tab',[_vm._v("RGPD")]),_c('v-tab',[_vm._v("Administration")])],1)],1)],1),_c('v-form',{ref:"form"},[_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{attrs:{"eager":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-radio-group',{attrs:{"row":"","rules":[
                  function (v) { return !!v || 'Ce champ est obligatoire'; }
                ]},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v("Civilité *")])]},proxy:true}],null,false,3321982576),model:{value:(_vm.user.civilite),callback:function ($$v) {_vm.$set(_vm.user, "civilite", $$v)},expression:"user.civilite"}},[_c('v-radio',{attrs:{"label":"Monsieur","value":1}}),_c('v-radio',{attrs:{"label":"Madame","value":2}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-radio-group',{attrs:{"row":"","rules":[
                  function (v) { return !!v || 'Ce champ est obligatoire'; }
                ]},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v("Titre *")])]},proxy:true}],null,false,1755024163),model:{value:(_vm.user.titre),callback:function ($$v) {_vm.$set(_vm.user, "titre", $$v)},expression:"user.titre"}},[_c('v-radio',{attrs:{"label":"Docteur","value":1}}),_c('v-radio',{attrs:{"label":"Professeur","value":2}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"Prénom *","rules":[
                  function (v) { return !!v || 'Ce champ est obligatoire'; },
                  function (v) { return (v && v.length > 1) || 'Ce champ doit contenir un minimum de 2 caractères'; } ],"required":""},model:{value:(_vm.user.prenom),callback:function ($$v) {_vm.$set(_vm.user, "prenom", $$v)},expression:"user.prenom"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"Nom *","rules":[
                  function (v) { return !!v || 'Ce champ est obligatoire'; },
                  function (v) { return (v && v.length > 1) || 'Ce champ doit contenir un minimum de 2 caractères'; } ],"required":""},model:{value:(_vm.user.nom),callback:function ($$v) {_vm.$set(_vm.user, "nom", $$v)},expression:"user.nom"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-radio-group',{attrs:{"row":"","rules":[
                  function (v) { return !!v || 'Ce champ est obligatoire'; }
                ]},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v("Type adresse *")])]},proxy:true}],null,false,2848307410),model:{value:(_vm.user.typeadr),callback:function ($$v) {_vm.$set(_vm.user, "typeadr", $$v)},expression:"user.typeadr"}},[_c('v-radio',{attrs:{"label":"Cabinet","value":1}}),_c('v-radio',{attrs:{"label":"Hôpital","value":2}}),_c('v-radio',{attrs:{"label":"Domicile","value":3}}),_c('v-radio',{attrs:{"label":"Autre","value":10}})],1)],1),(_vm.user.typeadr == 10)?_c('v-col',{attrs:{"cols":"12","md":"6"}},[(_vm.user.typeadr == 10)?_c('v-text-field',{attrs:{"label":"Autre type d'adresse *","rules":[
                  function (v) { return !!v || 'Ce champ est obligatoire'; },
                  function (v) { return (v && v.length > 1) || 'Ce champ doit contenir un minimum de 2 caractères'; } ],"required":""},model:{value:(_vm.user.typeadrautre),callback:function ($$v) {_vm.$set(_vm.user, "typeadrautre", $$v)},expression:"user.typeadrautre"}}):_vm._e()],1):_vm._e()],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"Adresse L1 *","rules":[
                  function (v) { return !!v || 'Ce champ est obligatoire'; },
                  function (v) { return (v && v.length >= 2) || 'Ce champ doit contenir un minimum de 2 caractères'; } ],"required":""},model:{value:(_vm.user.adressel1),callback:function ($$v) {_vm.$set(_vm.user, "adressel1", $$v)},expression:"user.adressel1"}}),_c('v-text-field',{attrs:{"label":"Adresse L2"},model:{value:(_vm.user.adressel2),callback:function ($$v) {_vm.$set(_vm.user, "adressel2", $$v)},expression:"user.adressel2"}}),_c('v-text-field',{attrs:{"label":"Adresse L3"},model:{value:(_vm.user.adressel3),callback:function ($$v) {_vm.$set(_vm.user, "adressel3", $$v)},expression:"user.adressel3"}}),_c('v-text-field',{attrs:{"label":"Adresse L4"},model:{value:(_vm.user.adressel4),callback:function ($$v) {_vm.$set(_vm.user, "adressel4", $$v)},expression:"user.adressel4"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"Boite postale"},model:{value:(_vm.user.bp),callback:function ($$v) {_vm.$set(_vm.user, "bp", $$v)},expression:"user.bp"}}),_c('v-text-field',{attrs:{"label":"Lieu-dit"},model:{value:(_vm.user.lieudit),callback:function ($$v) {_vm.$set(_vm.user, "lieudit", $$v)},expression:"user.lieudit"}}),_c('v-text-field',{attrs:{"label":"Code postal *","rules":[
                  function (v) { return !!v || 'Ce champ est obligatoire'; },
                  function (v) { return (v && v.length >= 2) || 'Ce champ doit contenir un minimum de 2 caractères'; } ],"required":""},model:{value:(_vm.user.cp),callback:function ($$v) {_vm.$set(_vm.user, "cp", $$v)},expression:"user.cp"}}),_c('v-text-field',{attrs:{"label":"Ville *","rules":[
                  function (v) { return !!v || 'Ce champ est obligatoire'; },
                  function (v) { return (v && v.length >= 2) || 'Ce champ doit contenir un minimum de 2 caractères'; } ],"required":""},model:{value:(_vm.user.ville),callback:function ($$v) {_vm.$set(_vm.user, "ville", $$v)},expression:"user.ville"}})],1)],1)],1),_c('v-tab-item',{attrs:{"eager":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"RPPS","rules":[
                  function (v) { return (!v || (!!v && v.length >= 11)) || 'Ce champ doit contenir un minimum de 11 chiffres'; },
                  function (v) { return (!v || Number.isInteger(Number(v))) || 'Ce champ doit contenir uniquement des chiffres'; } ]},model:{value:(_vm.user.rpps),callback:function ($$v) {_vm.$set(_vm.user, "rpps", $$v)},expression:"user.rpps"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-autocomplete',{attrs:{"items":_vm.specialites,"label":"Spécialité(s) médicale(s) *","rules":[
                  function (v) { return (v && v.length > 0) || 'Vous devez choisir une ou plusieurs spécialités'; } ],"required":"","multiple":"","clearable":"","small-chips":"","deletable-chips":""},model:{value:(_vm.user.specialites),callback:function ($$v) {_vm.$set(_vm.user, "specialites", $$v)},expression:"user.specialites"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"Téléphone *","rules":[
                  function (v) { return !!v || 'Ce champ est obligatoire'; },
                  function (v) { return (v && v.length >= 8) || 'Ce champ doit contenir un minimum de 8 chiffres'; },
                  function (v) { return Number.isInteger(Number(v)) || 'Ce champ doit contenir uniquement des chiffres'; } ],"required":""},model:{value:(_vm.user.telephone),callback:function ($$v) {_vm.$set(_vm.user, "telephone", $$v)},expression:"user.telephone"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"Portable","rules":[
                  function (v) { return (!v || (!!v && v.length >= 8)) || 'Ce champ doit contenir un minimum de 8 chiffres'; },
                  function (v) { return (!v || Number.isInteger(Number(v))) || 'Ce champ doit contenir uniquement des chiffres'; } ]},model:{value:(_vm.user.portable),callback:function ($$v) {_vm.$set(_vm.user, "portable", $$v)},expression:"user.portable"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"Télécopie","rules":[
                  function (v) { return (!v || (!!v && v.length >= 8)) || 'Ce champ doit contenir un minimum de 8 chiffres'; },
                  function (v) { return (!v || Number.isInteger(Number(v))) || 'Ce champ doit contenir uniquement des chiffres'; } ]},model:{value:(_vm.user.telecopie),callback:function ($$v) {_vm.$set(_vm.user, "telecopie", $$v)},expression:"user.telecopie"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"Email *","required":"","rules":[
                  function (v) { return !!v || 'Ce champ est obligatoire'; },
                  function (v) { return /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(v) || 'Adresse email invalide'; } ]},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}})],1)],1)],1),_c('v-tab-item',{attrs:{"eager":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-switch',{attrs:{"flat":"","label":"Ne souhaite pas être informé(e) des actualités et recevoir les offres exclusives des partenaires de l'Agence CCC"},model:{value:(_vm.user.optout),callback:function ($$v) {_vm.$set(_vm.user, "optout", $$v)},expression:"user.optout"}})],1)],1)],1),_c('v-tab-item',{attrs:{"eager":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-autocomplete',{attrs:{"items":_vm.roles,"label":"Groupe(s) *","rules":[
                  function (v) { return (v && v.length > 0) || 'Vous devez choisir au moins un groupe'; } ],"required":"","multiple":"","clearable":"","small-chips":"","deletable-chips":"","disabled":!_vm.can('admin_users_set_roles')},model:{value:(_vm.user.roles),callback:function ($$v) {_vm.$set(_vm.user, "roles", $$v)},expression:"user.roles"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-switch',{attrs:{"flat":"","label":"Statut"},model:{value:(_vm.user.statut),callback:function ($$v) {_vm.$set(_vm.user, "statut", $$v)},expression:"user.statut"}})],1)],1)],1)],1)],1),_c('p',{staticClass:"text-left"},[_c('small',[_vm._v("* Champs obligatoires")])])],1):_vm._e(),_c('v-card-actions',{staticClass:"pa-5"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","dark":"","depressed":""},on:{"click":_vm.cancel}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v(" mdi-chevron-left ")]),_vm._v(" Annuler ")],1),(_vm.showForm)?_c('v-btn',{attrs:{"color":"success","depressed":""},on:{"click":function($event){return _vm.save(false)}}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v(" mdi-content-save-edit-outline ")]),_vm._v(" Enregistrer ")],1):_vm._e(),(_vm.showForm)?_c('v-btn',{attrs:{"color":"success","depressed":""},on:{"click":function($event){return _vm.save(true)}}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v(" mdi-content-save-outline ")]),_vm._v(" Enregistrer & fermer ")],1):_vm._e(),_c('v-spacer')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }