<template>
  <v-card
    outlined
  >
    <v-card-title>
      <v-icon
        class="mr-2"
      >
        mdi-account-lock-outline
      </v-icon> 
      Groupes / Permissions
      <v-spacer />

      <v-btn icon>
        <v-icon v-if="loading">
          mdi-loading mdi-spin
        </v-icon>
        <v-icon 
          v-else 
          @click="refresh"
        >
          mdi-refresh
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-data-table
      :page="options.page"
      :page-count="pages"
      :headers="headers"
      :items="roles"
      :options.sync="options"
      :server-items-length="total"
      :loading="loading"
      :multi-sort="options.multiSort"
      :must-sort="options.mustSort"
      :items-per-page="options.itemsPerPage"
      :footer-props="{
        'items-per-page-options':[ 5, 10, 20, 50, 100 ]
      }"
    >
      <template v-slot:item="row">
        <tr
          :key="row.item.id"
        >
          <td class="text-left text-no-wrap">
            {{ row.item.name }}
          </td>
          <td class="text-left py-2">
            <v-chip
              v-for="permission in row.item.permissions" 
              :key="permission"                
              color="gray"
              text-color="white"
              small
              dark
              class="ma-1"
            >
              {{ permission }}
            </v-chip>
          </td>
          <td class="text-right text-no-wrap">
            <v-tooltip 
              top
              v-if="row.item.name != 'Super Admin' && can('admin_roles_edit')"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn 
                  fab 
                  dark 
                  x-small 
                  color="primary"
                  depressed
                  class="mx-1"
                  @click="editGroup(row.item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon dark>
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </template>
              <span>Modifier</span>
            </v-tooltip>  
            <v-tooltip 
              top
              v-if="row.item.name != 'Super Admin' && can('admin_roles_delete')"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn 
                  fab 
                  dark 
                  x-small 
                  color="error"
                  depressed
                  class="mx-1"
                  @click="deleteGroup(row.item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon dark>
                    mdi-trash-can-outline
                  </v-icon>
                </v-btn>
              </template>
              <span>Supprimer</span>
            </v-tooltip>  
          </td>
        </tr>
      </template>      
    </v-data-table>

    <v-card-actions
      class="pa-5"
    >
      <v-spacer />
      <v-btn
        color="primary"
        dark
        depressed
        @click="addGroup"
        v-if="can('admin_roles_add')"
      >
        <v-icon class="mr-1">
          mdi-plus
        </v-icon>
        Ajouter un groupe
      </v-btn>
      <v-spacer />
    </v-card-actions>

    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{ dialogTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                >
                  <v-text-field
                    label="Nom du groupe *"
                    v-model="formGroupe.nom"
                    :rules="nomRules"
                    required
                  />
                </v-col>
                <v-col
                  cols="12"
                >
                  <v-autocomplete
                    :items="permissions"
                    v-model="formGroupe.permissions"
                    label="Permissions"
                    multiple
                    clearable
                    small-chips
                    deletable-chips
                  />
                </v-col>
              </v-row>
            </v-container>
            <small>* Champs obligatoires</small>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="cancel"
          >
            Annuler
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="save"
          >
            Enregistrer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import api from './api'

export default {
  data() {
    return {
      total: 0,
      pages: 0,
      roles: [],
      permissions: [],
      loading: false,
      options: {},
      dialog: false,
      dialogTitle: 'Ajouter un groupe',
      nomRules: [
        v => !!v || 'Le nom du groupe est obligatoire',
        v => (v && v.length > 3) || 'Le nom doit contenir un minimum de 3 caractères',
      ],
      // permissionRules: [
      //   v => (v && v.length > 0) || 'Vous devez choisir une ou plusieurs permissions',
      // ],
      formGroupe: {
        id: null,
        nom: '',
        permissions: [],
      },
      headers: [
        { text: "Groupe", value: "name", sortable:true },
        { text: "Permissions", value: "actions", sortable: false },
        { text: "", value: null, sortable: false },
      ],
    };
  },
  watch: {
    options: {
      handler(){
        this.getRoles();
      },
      deep: true
    }
  },
  methods: {
    refresh(){
      this.total = 0;
      this.pages = 0,
      this.roles = [];
      this.permissions = [];
      this.loading = false;
      this.options = {
        page: 1, 
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: [ false ],
        groupBy: [],
        groupDesc: [],
        mustSort: false,
        multiSort: false,    
      };
      this.getRoles();
    },
    getRoles(){
      this.loading = true;
      this.$store.dispatch('permissions/setOptions',this.options);
      api.getRoles(this.options)
      .then( response => {
        this.loading = false;
        if (response.data){
          this.roles = response.data.roles;
          this.permissions = response.data.permissions;
          this.pages = response.data.pages;
          this.total = response.data.total;
        }
      })
      .catch(error => {
        this.loading = false;
        if (error.response) {
          // client received an error response (5xx, 4xx)
          if (error.response.status === 401){
            this.$store.dispatch('auth/logoutUser');
            this.$store.dispatch('overlay/setMessages',[{type:'error',message:'Votre session a expirée.'}]);
          } else {
            this.$store.dispatch('overlay/setMessages',[{type:'error',message:'Une erreur s\'est produite. Merci de réessayer.'}]);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          this.$store.dispatch('overlay/setMessages',[{type:'error',message:'Erreur de connexion. Merci de réessayer.'}]);
        } else {
          // anything else
          this.$store.dispatch('overlay/setMessages',[{type:'error',message:'Une erreur s\'est produite. Merci de réessayer.'}]);
        }
      });

    },
    resetForm(){
      this.formGroupe =  {
        id: null,
        nom: '',
        permissions: [],
      };
    },
    cancel(){
      this.dialog = false;
    },
    addGroup(){
      this.dialogTitle = "Ajouter un groupe";
      this.dialog = true;
      this.resetForm();
    },
    editGroup(item){
      this.dialogTitle = "Modifier un groupe";
      this.dialog = true;
      this.formGroupe.id = item.id;
      this.formGroupe.nom = item.name;
      this.formGroupe.permissions = item.permissions;
    },
    deleteGroup(item){
      this.$confirm('Supprimer le groupe "' + item.name + '" ?',{
        buttonTrueText: 'Supprimer',
        buttonFalseText: 'Annuler',
        buttonTrueColor: 'error',
        buttonFalseColor: 'default',
        buttonTrueFlat: true,
        buttonFalseFlat: true,
        color: 'warning',
        icon: 'warning',
        title: 'Attention !',
      }).then(res => {
        if (res){
          this.$store.dispatch('overlay/enable');
          api.delete(item.id)
          .then( () => {
            this.$store.dispatch('auth/getUser');
            this.getRoles();
            this.$store.dispatch('overlay/disable');
            this.$store.dispatch('overlay/setMessages',[{type:'success',message:'Groupe supprimé avec succès.'}]);
          })
          .catch(error => {
            this.$store.dispatch('overlay/disable');
            if (error.response) {
              // client received an error response (5xx, 4xx)
              if (error.response.status === 401){
                this.$store.dispatch('auth/logoutUser');
                this.$store.dispatch('overlay/setMessages',[{type:'error',message:'Votre session a expirée.'}]);
              } else {
                this.$store.dispatch('overlay/setMessages',[{type:'error',message:'Une erreur s\'est produite. Merci de réessayer.'}]);
              }
            } else if (error.request) {
              // client never received a response, or request never left
              this.$store.dispatch('overlay/setMessages',[{type:'error',message:'Erreur de connexion. Merci de réessayer.'}]);
            } else {
              // anything else
              this.$store.dispatch('overlay/setMessages',[{type:'error',message:'Une erreur s\'est produite. Merci de réessayer.'}]);
            }
          });
        }
      })
    },
    save(){
      if (this.$refs.form.validate()){
        this.$store.dispatch('overlay/enable');
        api.save(this.formGroupe)
        .then( () => {
          this.$store.dispatch('auth/getUser');
          this.getRoles();
          this.$store.dispatch('overlay/disable');
          this.$store.dispatch('overlay/setMessages',[{type:'success',message:'Groupe enregistré avec succès.'}]);
        })
        .catch(error => {
          this.$store.dispatch('overlay/disable');
          if (error.response) {
            // client received an error response (5xx, 4xx)
            if (error.response.status === 401){
              this.$store.dispatch('auth/logoutUser');
              this.$store.dispatch('overlay/setMessages',[{type:'error',message:'Votre session a expirée.'}]);
            } else {
              this.$store.dispatch('overlay/setMessages',[{type:'error',message:'Une erreur s\'est produite. Merci de réessayer.'}]);
            }
          } else if (error.request) {
            // client never received a response, or request never left
            this.$store.dispatch('overlay/setMessages',[{type:'error',message:'Erreur de connexion. Merci de réessayer.'}]);
          } else {
            // anything else
            this.$store.dispatch('overlay/setMessages',[{type:'error',message:'Une erreur s\'est produite. Merci de réessayer.'}]);
          }
        });
        this.cancel();
      }
    }
  },
  beforeMount(){
    this.options = this.$store.getters['permissions/getOptions'];
  },
  mounted() {
    this.getRoles();
  },
};
</script>
