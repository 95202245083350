import Utilisateurs     from './list/Utilisateurs.vue'
import UtilisateurView from './view/UtilisateurView.vue'
import UtilisateurEdit from './edit/UtilisateurEdit.vue'

export const UtilisateursRoutes = [

    // List users
    {
        path: '/utilisateurs',
        name: 'Utilisateurs',
        component: Utilisateurs,
        meta: { 
            title: 'Utilisateurs',
            requiresAuth: true,
        }
    },
    
    // Add user
    {
        path: '/utilisateurs/creation',
        name: 'UtilisateursAdd',
        component: UtilisateurEdit,
        meta: { 
            title: 'Modifier utilisateur',
            requiresAuth: true
        }
    },

    // View user
    {
        path: '/utilisateurs/:id',
        name: 'UtilisateurView',
        component: UtilisateurView,
        meta: { 
            title: 'Afficher utilisateur',
            requiresAuth: true 
        }
    },

    // Edit user
    {
        path: '/utilisateurs/:id/modifier',
        name: 'UtilisateurEdit',
        component: UtilisateurEdit,
        meta: { 
            title: 'Modifier utilisateur',
            requiresAuth: true 
        }
    },

]