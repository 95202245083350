<template>
  <v-card outlined>
    <v-card-title>
      <v-icon class="mr-2">
        mdi-book-open-outline
      </v-icon>
      Liste DPC
      <v-spacer />

      <v-btn icon>
        <v-icon v-if="loading">
          mdi-loading mdi-spin
        </v-icon>
        <v-icon
          v-else
          @click="refresh"
        >
          mdi-refresh
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-container>
      <v-row>
        <v-col class="col-6">
          <v-text-field
            v-model="options.search"
            append-icon="mdi-magnify"
            label="Search"
            clearable
          />
        </v-col>
        <v-col class="col-6">
          <v-autocomplete
            :items="themes"
            v-model="options.themes"
            label="Thème(s)"
            multiple
            clearable
            small-chips
            deletable-chips
          />
        </v-col>
        <v-col class="col-4">
          <v-select
            :items="listeSelectInscription"
            v-model="options.inscription"
            label="Possibilité d'inscription"
          />
        </v-col>
        <v-col class="col-4">
          <v-select
            :items="listeSelectStatut"
            v-model="options.statut"
            label="Statut"
          />
        </v-col>
        <v-col class="col-4">
          <v-switch
            color="red"
            hide-details
            v-model="options.supprime"
            flat
            inset
            label="Supprimé"
          />
        </v-col>
      </v-row>
    </v-container>

    <v-data-table
      :page="options.page"
      :page-count="pages"
      :headers="headers"
      :items="liste"
      :options.sync="options"
      :server-items-length="total"
      :loading="loading"
      :multi-sort="options.multiSort"
      :must-sort="options.mustSort"
      :items-per-page="options.itemsPerPage"
      :footer-props="{
        'items-per-page-options': [5, 10, 20, 50, 100]
      }"
    >
      <template v-slot:item="row">
        <tr :key="row.item.id">
          <td class="text-left">
            {{ row.item.id }}
          </td>
          <td class="text-left">
            {{ row.item.titre }}
          </td>
          <td class="text-left">
            {{ row.item.dpcref }}
          </td>
          <td class="text-right text-no-wrap">
            <v-tooltip
              top
              v-if="!row.item.deleted"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  fab
                  dark
                  x-small
                  color="primary"
                  depressed
                  class="mx-1"
                  @click="viewDpc()"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon dark>
                    mdi-book-open-outline
                  </v-icon>
                </v-btn>
              </template>
              <span>Afficher informations</span>
            </v-tooltip>
            <v-tooltip
              top
              v-if="!row.item.deleted"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  fab
                  dark
                  x-small
                  color="primary"
                  depressed
                  class="mx-1"
                  @click="downloadEssentiels(row.item.id)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon dark>
                    mdi-file-download-outline
                  </v-icon>
                </v-btn>
              </template>
              <span>Télécharger les Essentiels</span>
            </v-tooltip>
            <v-tooltip
              top
              v-if="!row.item.deleted && row.item.statut == 1 && can('admin_dpc_edit')"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  fab
                  dark
                  x-small
                  :color="row.item.inscription == 1 ? 'success' : 'grey'"
                  depressed
                  class="mx-1"
                  @click="changeInscription(row.item.id, 1 - row.item.inscription)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon
                    dark
                    v-if="row.item.inscription == 1"
                  >
                    mdi-account-check
                  </v-icon>
                  <v-icon
                    dark
                    v-else
                  >
                    mdi-account-cancel
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ row.item.inscription == 1 ? 'Inscription activée' : 'Inscription désactivée' }}</span>
            </v-tooltip>
            <v-tooltip
              top
              v-if="!row.item.deleted && can('admin_dpc_edit')"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  fab
                  dark
                  x-small
                  :color="row.item.statut == 1 ? 'success' : 'grey'"
                  depressed
                  class="mx-1"
                  @click="changeState(row.item.id, (row.item.statut + 1) % 3)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon
                    dark
                    v-if="row.item.statut == 1"
                  >
                    mdi-check
                  </v-icon>
                  <v-icon
                    dark
                    v-else-if="row.item.statut == 2"
                  >
                    mdi-archive
                  </v-icon>
                  <v-icon
                    dark
                    v-else
                  >
                    mdi-cancel
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ row.item.statut == 1 ? 'Publié' : row.item.statut == 2 ? 'Archivé' : 'Dépublié' }}</span>
            </v-tooltip>
            <v-tooltip
              top
              v-if="!row.item.deleted && can('admin_dpc_edit')"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  fab
                  dark
                  x-small
                  color="warning"
                  depressed
                  class="mx-1"
                  @click="editDpc()"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon dark>
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </template>
              <span>Modifier le DPC</span>
            </v-tooltip>
            <v-tooltip
              top
              v-if="row.item.deleted && can('admin_dpc_delete')"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  fab
                  dark
                  x-small
                  color="success"
                  depressed
                  class="mx-1"
                  @click="restoreDpc(row.item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon dark>
                    mdi-delete-restore
                  </v-icon>
                </v-btn>
              </template>
              <span>Restaurer ce DPC</span>
            </v-tooltip>
            <v-tooltip
              top
              v-if="can('admin_dpc_delete')"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  fab
                  dark
                  x-small
                  color="error"
                  depressed
                  class="mx-1"
                  @click="deleteDpc(row.item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon dark>
                    mdi-trash-can-outline
                  </v-icon>
                </v-btn>
              </template>
              <span>Supprimer ce DPC</span>
            </v-tooltip>
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import api from './api'

export default {
  data() {
    return {
      total: 0,
      pages: 0,
      liste: [],
      listeSelectInscription: [
        { text: 'Tout afficher', value: null },
        { text: 'Inscription possible', value: 1 },
        { text: 'Inscription désactivée', value: 0 },
      ],
      listeSelectStatut: [
        { text: 'Tout afficher', value: null },
        { text: 'Activé', value: 1 },
        { text: 'Désactivé', value: 0 },
        { text: 'Archivé', value: 2 },
      ],
      themes: [],
      loading: false,
      options: {

        page: 1,
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: [false],
        groupBy: [],
        groupDesc: [],
        mustSort: false,
        multiSort: false,

        search: '',
        themes: [],
        inscription: null,
        statut: null,
        supprime: false,

      },
      headers: [
        { text: "#", value: "id", sortable: true },
        { text: "DPC", value: "titre", sortable: true },
        { text: "REFERENCE", value: "dpcref", sortable: true },
        { text: "", value: null, sortable: false },
      ],
    };
  },
  watch: {
    options: {
      handler() {
        this.getListe();
      },
      deep: true
    }
  },
  methods: {
    // Get list themes
    getParams() {

      api.getParams()
        .then(response => {

          if (response.data) {
            this.themes = response.data.themes;
          }

        })
        .catch(error => {

          if (error.response) {
            // client received an error response (5xx, 4xx)
            if (error.response.status === 401) {
              this.$store.dispatch('auth/logoutUser');
              this.$store.dispatch('overlay/setMessages', [{ type: 'error', message: 'Votre session a expirée.' }]);
            } else {
              this.$store.dispatch('overlay/setMessages', [{ type: 'error', message: 'Une erreur s\'est produite. Merci de réessayer.' }]);
            }
          } else if (error.request) {
            // client never received a response, or request never left
            this.$store.dispatch('overlay/setMessages', [{ type: 'error', message: 'Erreur de connexion. Merci de réessayer.' }]);
          } else {
            // anything else
            this.$store.dispatch('overlay/setMessages', [{ type: 'error', message: 'Une erreur s\'est produite. Merci de réessayer.' }]);
          }

        });

    },
    refresh() {
      this.$store.dispatch('dpc/init');
      this.options = this.$store.getters['dpc/getOptions'];
      this.getListe();
    },
    // Get list of DPC
    getListe() {

      this.$store.dispatch('dpc/setOptions', this.options);
      this.loading = true;

      api.getListe(this.options)
        .then(response => {

          this.loading = false;
          if (response.data) {
            this.liste = response.data.liste;
            this.pages = response.data.pages;
            this.total = response.data.total;
          }

        })
        .catch(error => {

          this.loading = false;
          if (error.response) {
            // client received an error response (5xx, 4xx)
            if (error.response.status === 401) {
              this.$store.dispatch('auth/logoutUser');
              this.$store.dispatch('overlay/setMessages', [{ type: 'error', message: 'Votre session a expirée.' }]);
            } else {
              this.$store.dispatch('overlay/setMessages', [{ type: 'error', message: 'Une erreur s\'est produite. Merci de réessayer.' }]);
            }
          } else if (error.request) {
            // client never received a response, or request never left
            this.$store.dispatch('overlay/setMessages', [{ type: 'error', message: 'Erreur de connexion. Merci de réessayer.' }]);
          } else {
            // anything else
            this.$store.dispatch('overlay/setMessages', [{ type: 'error', message: 'Une erreur s\'est produite. Merci de réessayer.' }]);
          }

        });

    },
    restoreDpc(item) {
      this.$confirm('Restaurer le DPC : "' + item.titre + '"?', {
        buttonTrueText: 'Restaurer',
        buttonFalseText: 'Annuler',
        buttonTrueColor: 'success',
        buttonFalseColor: 'default',
        buttonTrueFlat: true,
        buttonFalseFlat: true,
        color: 'warning',
        icon: 'warning',
        title: 'Attention !',
      }).then(res => {
        if (res) {

          this.$store.dispatch('overlay/enable');
          api.restore(item.id)
            .then(() => {
              this.getListe();
              this.$store.dispatch('overlay/disable');
              // this.$store.dispatch('overlay/setMessages',[{type:'success',message:'Session restaurée avec succès.'}]);
            })
            .catch(error => {
              this.$store.dispatch('overlay/disable');
              if (error.response) {
                if (error.response.status === 422) {
                  this.$store.dispatch('overlay/setMessages', [{ type: 'warning', message: error.response.data.messages.join('<br>') }]);
                } else if (error.response.status === 401) {
                  // client received an error response (5xx, 4xx)
                  this.$store.dispatch('auth/logoutUser');
                  this.$store.dispatch('overlay/setMessages', [{ type: 'error', message: 'Votre session a expirée.' }]);
                } else {
                  this.$store.dispatch('overlay/setMessages', [{ type: 'error', message: 'Une erreur s\'est produite. Merci de réessayer.' }]);
                }
              } else if (error.request) {
                // client never received a response, or request never left
                this.$store.dispatch('overlay/setMessages', [{ type: 'error', message: 'Erreur de connexion. Merci de réessayer.' }]);
              } else {
                // anything else
                this.$store.dispatch('overlay/setMessages', [{ type: 'error', message: 'Une erreur s\'est produite. Merci de réessayer.' }]);
              }
            });

        }
      })
    },
    deleteDpc(item) {
      this.$confirm('Supprimer le DPC : "' + item.titre + '"?', {
        buttonTrueText: 'Supprimer',
        buttonFalseText: 'Annuler',
        buttonTrueColor: 'error',
        buttonFalseColor: 'default',
        buttonTrueFlat: true,
        buttonFalseFlat: true,
        color: 'warning',
        icon: 'warning',
        title: 'Attention !',
      }).then(res => {
        if (res) {
          this.$store.dispatch('overlay/enable');
          api.delete(item.id, item.deleted)
            .then(() => {
              this.getListe();
              this.$store.dispatch('overlay/disable');
            })
            .catch(error => {
              this.$store.dispatch('overlay/disable');
              if (error.response) {
                if (error.response.status === 422) {
                  this.$store.dispatch('overlay/setMessages', [{ type: 'warning', message: error.response.data.messages.join('<br>') }]);
                } else if (error.response.status === 401) {
                  // client received an error response (5xx, 4xx)
                  this.$store.dispatch('auth/logoutUser');
                  this.$store.dispatch('overlay/setMessages', [{ type: 'error', message: 'Votre session a expirée.' }]);
                } else {
                  this.$store.dispatch('overlay/setMessages', [{ type: 'error', message: 'Une erreur s\'est produite. Merci de réessayer.' }]);
                }
              } else if (error.request) {
                // client never received a response, or request never left
                this.$store.dispatch('overlay/setMessages', [{ type: 'error', message: 'Erreur de connexion. Merci de réessayer.' }]);
              } else {
                // anything else
                this.$store.dispatch('overlay/setMessages', [{ type: 'error', message: 'Une erreur s\'est produite. Merci de réessayer.' }]);
              }
            });
        }
      })
    },
    changeState(id, state) {
      this.$confirm('Modifier le statut de ce DPC?', {
        buttonTrueText: 'Modifier',
        buttonFalseText: 'Annuler',
        buttonTrueColor: 'error',
        buttonFalseColor: 'default',
        buttonTrueFlat: true,
        buttonFalseFlat: true,
        color: 'warning',
        icon: 'warning',
        title: 'Attention !',
      }).then(res => {
        if (res) {
          this.$store.dispatch('overlay/enable');
          api.changeState(id, state)
            .then(() => {
              this.getListe();
              this.$store.dispatch('overlay/disable');
            })
            .catch(error => {
              this.$store.dispatch('overlay/disable');
              if (error.response) {
                if (error.response.status === 422) {
                  this.$store.dispatch('overlay/setMessages', [{ type: 'warning', message: error.response.data.messages.join('<br>') }]);
                } else if (error.response.status === 401) {
                  // client received an error response (5xx, 4xx)
                  this.$store.dispatch('auth/logoutUser');
                  this.$store.dispatch('overlay/setMessages', [{ type: 'error', message: 'Votre session a expirée.' }]);
                } else {
                  this.$store.dispatch('overlay/setMessages', [{ type: 'error', message: 'Une erreur s\'est produite. Merci de réessayer.' }]);
                }
              } else if (error.request) {
                // client never received a response, or request never left
                this.$store.dispatch('overlay/setMessages', [{ type: 'error', message: 'Erreur de connexion. Merci de réessayer.' }]);
              } else {
                // anything else
                this.$store.dispatch('overlay/setMessages', [{ type: 'error', message: 'Une erreur s\'est produite. Merci de réessayer.' }]);
              }
            });
        }
      })
    },
    changeInscription(id, state) {
      this.$confirm('Modifier la possibilité de s\'inscrire à ce DPC?', {
        buttonTrueText: 'Modifier',
        buttonFalseText: 'Annuler',
        buttonTrueColor: 'error',
        buttonFalseColor: 'default',
        buttonTrueFlat: true,
        buttonFalseFlat: true,
        color: 'warning',
        icon: 'warning',
        title: 'Attention !',
      }).then(res => {
        if (res) {
          this.$store.dispatch('overlay/enable');
          api.changeInscription(id, state)
            .then(() => {
              this.getListe();
              this.$store.dispatch('overlay/disable');
            })
            .catch(error => {
              this.$store.dispatch('overlay/disable');
              if (error.response) {
                if (error.response.status === 422) {
                  this.$store.dispatch('overlay/setMessages', [{ type: 'warning', message: error.response.data.messages.join('<br>') }]);
                } else if (error.response.status === 401) {
                  // client received an error response (5xx, 4xx)
                  this.$store.dispatch('auth/logoutUser');
                  this.$store.dispatch('overlay/setMessages', [{ type: 'error', message: 'Votre session a expirée.' }]);
                } else {
                  this.$store.dispatch('overlay/setMessages', [{ type: 'error', message: 'Une erreur s\'est produite. Merci de réessayer.' }]);
                }
              } else if (error.request) {
                // client never received a response, or request never left
                this.$store.dispatch('overlay/setMessages', [{ type: 'error', message: 'Erreur de connexion. Merci de réessayer.' }]);
              } else {
                // anything else
                this.$store.dispatch('overlay/setMessages', [{ type: 'error', message: 'Une erreur s\'est produite. Merci de réessayer.' }]);
              }
            });
        }
      })
    },
    viewDpc(){
      return false;
    },
    downloadEssentiels(id){
      window.open(process.env.VUE_APP_URL + '/admin/dpc/' + id + '/downloadEssentiels', '_blank');
    },
    editDpc(){
      return false;
    }
  },
  beforeMount() {
    this.options = this.$store.getters['dpc/getOptions'];
    this.getParams();
  },
  mounted() {
    this.getListe();
  },
};

</script>
