import 'material-design-icons-iconfont/dist/material-design-icons.css'
import '@mdi/font/css/materialdesignicons.css'
import Vue      from 'vue';
import Vuetify  from 'vuetify/lib';
// Translation provided by Vuetify (javascript)
import frFr from 'vuetify/lib/locale/fr'
// import colors   from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'md',
    },
    lang: {
      locales: { frFr },
      current: 'frFr',
    },
    theme: {
      options: { customProperties: true },
        themes: {
            light: {
                primary: '#2384db',
                secondary: '#424242',
                accent: '#82B1FF',
                error: '#FF5252',
                info: '#2196F3',
                success: '#2E7D32',
                warning: '#FF8F00',            
            },
        },
    },
});
