const state = {
  options: {

    page: 1, 
    itemsPerPage: 10,
    sortBy: [],
    sortDesc: [ false ],
    groupBy: [],
    groupDesc: [],
    mustSort: false,
    multiSort: false, 

    search: '',
    themes:[],
    inscription:null,
    statut:null,
    supprime: false,

  },
};

const getters = {
  getOptions      : (state) => state.options,
};

const actions = {

  init({commit}){
    commit('SET_OPTIONS', {

      search: '',
      themes:[],
      inscription:null,
      statut:null,
      supprime: false,
      page: 1, 
      itemsPerPage: 10,
      sortBy: [],
      sortDesc: [ false ],
      groupBy: [],
      groupDesc: [],
      mustSort: false,
      multiSort: false,    
    });
  },

  setOptions({commit},options){
    commit('SET_OPTIONS', options);
  },

};

const mutations = {
  SET_OPTIONS(state,options) {
    state.options = options;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
