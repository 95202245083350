import Api      from "@/apis/Api.js";
import Csrf     from "@/apis/Csrf"

export default {
    
  // Get list of parameters
  async getParams(){
    await Csrf.getCookie();
    return Api.post(`/admin/sessions/params`);
  },

  // Get list of sessions
  async getListe(options){
    await Csrf.getCookie();
    return Api.post(`/admin/sessions/index`,options);
  },

  // Delete session
  async delete(id, permanent){
    await Csrf.getCookie();
    if (permanent){
      return Api.post(`/admin/sessions/session/delete`,{'id':id});
    } else {
      return Api.post(`/admin/sessions/session/trash`,{'id':id});
    }
  },

  // Remove delay
  async nodelay(id){
    await Csrf.getCookie();
    return Api.post(`/admin/sessions/session/nodelay`,{'id':id});
  },

  // Restore session
  async restore(id){
    await Csrf.getCookie();
    return Api.post(`/admin/sessions/session/restore`,{'id':id});
  },

}