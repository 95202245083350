import Api      from "@/apis/Api.js";
import Csrf     from "@/apis/Csrf"

export default {
 
  // Get session data
  async getData(id){
    await Csrf.getCookie();
    return Api.post(`/admin/sessions/session`,{'id':id});
  },

}