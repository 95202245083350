const state = {
    show: true,
};

const getters = {
    isOpen: (state) => state.show
};

const actions = {
    toggle({commit}){
        commit('TOGGLE');
    },
};

const mutations = {
    SHOW(state) {
        state.show = true;
    },
    HIDE(state){
        state.show = false;
    },
    TOGGLE(state) {
        state.show = !state.show;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
