import Vue      from 'vue'
import store    from './store'
import router   from './router'
import App      from './App.vue'

// Vuetify
import vuetify  from './plugins/vuetify'

// Vuetify dialog confirmation
import VuetifyConfirm from 'vuetify-confirm'
Vue.use(VuetifyConfirm, { vuetify })

// Vue animation
import VueAnimated from '@codekraft-studio/vue-animated'
Vue.use(VueAnimated)

Vue.config.productionTip = false

Vue.mixin({
  computed:{
    currentUser(){
      return store.getters['auth/getUser'];
    }
  },
  methods: {
    can(permission){
      return this.currentUser.permissions.includes(permission);
    },
  }
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  beforeCreate() { 
    this.$store.dispatch('init');
    this.$store.dispatch('auth/getUser');
  }
}).$mount('#app')
