import userApi from './api'
import router from "@/router"

const state = {
  loggedIn: false,
  user: null,
  errors: [],
};

const getters = {
  isLoggedIn: (state) => state.loggedIn,
  getUser: (state) => state.user,
  getUsername: (state) => state.user.name,
  getErrors: (state) => state.errors
};

const actions = {
  init({commit}){
    commit('setUser', null);
    commit('setLoggedIn', false);
    commit('setErrors',[]);
  },
  getUser({commit,dispatch}){
    dispatch('overlay/enable',null,{root: true});
    userApi.current()
    .then((response) => {
      dispatch('overlay/disable',null,{root: true});
      if (response.data.user){
        commit('setUser', response.data.user);
        commit('setLoggedIn', true);
      }
    })
    .catch(error => {
      dispatch('overlay/disable',null,{root: true});
      if (error.response) {
        // client received an error response (5xx, 4xx)
        if (error.response.status === 401){
          // dispatch('logoutUser');
          // dispatch('overlay/setMessages',[{type:'error',message:'Votre session a expirée.'}],{root: true});
        } else {
          dispatch('overlay/setMessages',[{type:'error',message:'Une erreur s\'est produite. Merci de réessayer.'}],{root: true});
        }
      } else if (error.request) {
        // client never received a response, or request never left
        dispatch('overlay/setMessages',[{type:'error',message:'Erreur de connexion. Merci de réessayer.'}],{root: true});
      } else {
        // anything else
        dispatch('overlay/setMessages',[{type:'error',message:'Une erreur s\'est produite. Merci de réessayer.'}],{root: true});
      }
    });
  },
  loginUser({commit,dispatch},form){
    dispatch('overlay/enable',null,{root: true});
    userApi.login(form)
    .then( response => {
      dispatch('overlay/disable',null,{root: true});
      if (response.data.user){
        commit('setUser', response.data.user);
        commit('setLoggedIn', true);
        router.push({ name: 'Dashboard' })
      }
    })
    .catch(error => {
      dispatch('overlay/disable',null,{root: true});
      if (error.response) {
        // client received an error response (5xx, 4xx)
        if (error.response.status === 422){
          commit('setErrors', error.response.data.errors);
        } else {
          dispatch('overlay/setMessages',[{type:'error',message:'Une erreur s\'est produite. Merci de réessayer.'}],{root: true});
        }
      } else if (error.request) {
        // client never received a response, or request never left
        dispatch('overlay/setMessages',[{type:'error',message:'Erreur de connexion. Merci de réessayer.'}],{root: true});
      } else {
        // anything else
        dispatch('overlay/setMessages',[{type:'error',message:'Une erreur s\'est produite. Merci de réessayer.'}],{root: true});
      }
    });
  },
  logoutUser({commit,dispatch}){
    dispatch('overlay/enable',null,{root: true});
    userApi.logout()
    .then(() => {
      commit('setUser', null);
      commit('setLoggedIn', false);
      router.push({ name: 'Connexion' })
      dispatch('overlay/disable',null,{root: true});
    })
    .catch(() => {
      dispatch('overlay/disable',null,{root: true});
      dispatch('overlay/setMessages',[{type:'error',message:'Une erreur s\'est produite. Merci de réessayer.'}],{root: true});
    });   

  },
  requestReset({commit,dispatch},form){

    dispatch('overlay/enable',null,{root: true});

    userApi.requestReset(form)
    .then( response => {

      dispatch('overlay/disable',null,{root: true});

      if (response.data['data'] == 'passwords.sent'){
        router.push({ name: 'Connexion' })
        dispatch('overlay/setMessages',[{type:'success',message:response.data['message']}],{root: true});
      } else {
        dispatch('overlay/setMessages',[{type:'error',message:response.data['message']}],{root: true});
      }

    })
    .catch(error => {

      dispatch('overlay/disable',null,{root: true});

      if (error.response) {
        // client received an error response (5xx, 4xx)
        if (error.response.status === 422){
          commit('setErrors', error.response.data.errors);
        } else {
          dispatch('overlay/setMessages',[{type:'error',message:'Une erreur s\'est produite. Merci de réessayer.'}],{root: true});
        }
      } else if (error.request) {
        // client never received a response, or request never left
        dispatch('overlay/setMessages',[{type:'error',message:'Erreur de connexion. Merci de réessayer.'}],{root: true});
      } else {
        // anything else
        dispatch('overlay/setMessages',[{type:'error',message:'Une erreur s\'est produite. Merci de réessayer.'}],{root: true});
      }

    });
  },
  resetPassword({commit,dispatch},form){

    dispatch('overlay/enable',null,{root: true});

    userApi.resetPassword(form)
    .then( response => {

      dispatch('overlay/disable',null,{root: true});

      if (response.data['data'] == 'passwords.reset'){
        router.push({ name: 'Connexion' })
        dispatch('overlay/setMessages',[{type:'success',message:response.data['message']}],{root: true});
      } else {
        dispatch('overlay/setMessages',[{type:'error',message:response.data['message']}],{root: true});
      }

    })
    .catch(error => {

      dispatch('overlay/disable',null,{root: true});

      if (error.response) {
        // client received an error response (5xx, 4xx)
        if (error.response.status === 422){
          commit('setErrors', error.response.data.errors);
        } else {
          dispatch('overlay/setMessages',[{type:'error',message:'Une erreur s\'est produite. Merci de réessayer.'}],{root: true});
        }
      } else if (error.request) {
        // client never received a response, or request never left
        dispatch('overlay/setMessages',[{type:'error',message:'Erreur de connexion. Merci de réessayer.'}],{root: true});
      } else {
        // anything else
        dispatch('overlay/setMessages',[{type:'error',message:'Une erreur s\'est produite. Merci de réessayer.'}],{root: true});
      }

    });
  },
  clearUser({commit}){
    commit('setUser', null);
    commit('setLoggedIn', false);
  },
  clearErrors({commit}){
    commit('setErrors',[]);
  },
};

const mutations = {
  setUser(state,user) {
    state.user = user;
  },
  setLoggedIn(state,value) {
    state.loggedIn = value;
  },
  setErrors(state,errors){
    state.errors = errors;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
