<template>
  <v-card outlined>
    <template v-if="!loading && session.dpc">
      <v-card-title>
        <v-icon class="mr-2">
          mdi-school-outline
        </v-icon>
        {{ session.dpc.titre }}

        <v-spacer />

        <v-btn icon>
          <v-icon @click="retour">
            mdi-arrow-left-bold-circle-outline
          </v-icon>
        </v-btn>
        <v-btn icon>
          <v-icon v-if="loading">
            mdi-loading mdi-spin
          </v-icon>
          <v-icon
            v-else
            @click="refresh"
          >
            mdi-refresh
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-subtitle class="text-left ml-8">
        <em>Référence :</em> <span class="font-weight-bold black--text">{{ session.dpc.dpcref }}</span> <span class="mx-2">|</span> <em>Session :</em> <span class="font-weight-bold black--text">{{ session.dpc.dpcsession }}</span>
      
        <v-spacer />
        <em class="mr-1">Par :</em>
        <a
          v-if="can('admin_users_access')"
          @click.prevent="viewUser(session.user.id)"
        >{{ session.user.prenom }} {{
          session.user.nom
        }}</a>
        <span v-else>{{ session.user.prenom }} {{ session.user.nom }}</span>
      </v-card-subtitle>

      <v-card-text v-if="loading || !session.id">
        <v-icon
          color="primary"
          large
        >
          mdi-loading mdi-spin
        </v-icon>
      </v-card-text>

      <v-container
        fluid
        v-else
      >
        <v-row>
          <v-col
            cols="12"
            sm="6"
            md="4"
            lg="4"
            xl="4"
          >
            <v-card
              outlined
              class="dpc"
            >
              <v-img
                height="200"
                :src="session.dpc.image"
              >
                <template v-slot:placeholder>
                  <v-layout
                    fill-height
                    align-center
                    justify-center
                    ma-0
                  >
                    <v-progress-circular
                      indeterminate
                      color="warning"
                    />
                  </v-layout>
                </template>

                <v-container>
                  <h3 class="p-0 m-0">
                    {{ session.dpc.titre }}
                  </h3>
                  <div class="my-2">
                    <v-chip
                      v-for="theme in session.dpc.themes"
                      :key="theme.id"
                      class="mr-1 mt-1"
                      x-small
                    >
                      {{ theme.titre }}
                    </v-chip>
                  </div>
                </v-container>
              </v-img>

              <v-card-text
                v-html="session.dpc.intro"
                class="pb-0"
              />

              <v-divider class="my-5" />

              <v-list-item
                dense
                class="my-0"
              >
                <v-list-item-icon>
                  <v-icon>mdi-clock-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Inscription le {{ session.creation_date }}</v-list-item-title>
                  <v-list-item-title>Validation le {{ session.validation_date }}</v-list-item-title>
                  <v-list-item-title>
                    Validation par
                    <a
                      v-if="can('admin_users_access')"
                      @click.prevent="viewUser(session.validation_user.id)"
                    >{{
                      session.validation_user.prenom
                    }} {{ session.validation_user.nom }}</a>
                    <span v-else>{{ session.validation_user.prenom }} {{ session.validation_user.nom }}</span>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item
                dense
                class="my-0"
                v-if="session.progress > 0"
              >
                <v-list-item-icon>
                  <v-icon>mdi-percent-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-progress-linear
                    v-model="session.progress"
                    height="30"
                    color="green"
                    striped
                    rounded
                  >
                    <strong>{{ session.progress }}%</strong>
                  </v-progress-linear>
                </v-list-item-content>
              </v-list-item>

              <v-list-item
                dense
                class="my-0"
              >
                <v-list-item-icon>
                  <v-icon>mdi-shoe-print</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Etape actuelle : {{ session.current }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-spacer class="mb-5" />
            </v-card>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="8"
            lg="8"
            xl="8"
            v-if="session.recap"
          >
            <v-card outlined>
              <v-card-subtitle class="d-block grey darken-2 white--text px-4 py-2">
                <h3>Récapitulatif parcours</h3>
              </v-card-subtitle>

              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        Etape
                      </th>
                      <th class="text-center">
                        Début
                      </th>
                      <th class="text-center">
                        Fin
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="etape in session.recap.etapes"
                      :key="etape.id"
                    >
                      <td class="text-left">
                        {{ etape.titre }}
                      </td>
                      <td class="text-center">
                        {{ etape.created_at }}
                      </td>
                      <td class="text-center">
                        {{ etape.updated_at }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>

            <v-card
              outlined
              class="text-left mt-6"
              v-if="session.grilles.length > 0"
            >
              <v-card-subtitle class="d-block grey darken-2 white--text px-4 py-2">
                <h3>Audit clinique</h3>
              </v-card-subtitle>

              <v-simple-table>
                <template v-slot:default>
                  <tbody>
                    <tr
                      v-for="grille in session.grilles"
                      :key="grille.id"
                    >
                      <td class="text-left">
                        {{ grille.date }}
                      </td>
                      <td class="text-center">
                        Audit Clinique {{ grille.type }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="12"
            lg="12"
            xl="12"
            v-if="session.reponses.length > 0"
          >
            <v-card outlined>
              <v-card-subtitle class="d-block grey darken-2 white--text px-4 py-2">
                <h3>Récapitulatif réponses</h3>
              </v-card-subtitle>

              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        Début
                      </th>
                      <th class="text-left">
                        Fin
                      </th>
                      <th class="text-left">
                        Section
                      </th>
                      <th class="text-center">
                        No. de questions
                      </th>
                      <th class="text-center">
                        Bonnes réponses
                      </th>
                      <th class="text-center">
                        % Bonnes réponses
                      </th>
                      <th class="text-center">
                        Degré de certitude
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-for="dpc in session.reponses">
                      <tr :key="dpc.id">
                        <td class="text-left">
                          {{ dpc.debut }}
                        </td>
                        <td class="text-left">
                          {{ dpc.fin }}
                        </td>
                        <td class="text-left">
                          {{ dpc.titre }}
                        </td>
                        <td class="text-center">
                          {{ (dpc.maxscore > 0) ? dpc.maxscore : '' }}
                        </td>
                        <td class="text-center">
                          {{ (dpc.maxscore > 0) ? dpc.score : '' }}
                        </td>
                        <td class="text-center">
                          {{ (dpc.maxscore > 0) ? Math.round(dpc.score / dpc.maxscore * 100) + '%' : '' }}
                        </td>
                        <td class="text-center">
                          {{ (dpc.maxscore > 0) ? dpc.evaluation + '%' : '' }}
                        </td>
                      </tr>
                    </template>
                  </tbody>
                  <tfoot>
                    <tr>
                      <th class="text-left">
                        {{ tableData.footer[0] }}
                      </th>
                      <th class="text-left">
                        {{ tableData.footer[1] }}
                      </th>
                      <th class="text-left">
                        {{ tableData.footer[2] }}
                      </th>
                      <th class="text-center">
                        {{ tableData.footer[3] }}
                      </th>
                      <th class="text-center">
                        {{ tableData.footer[4] }}
                      </th>
                      <th class="text-center">
                        {{ tableData.footer[5] }}
                      </th>
                      <th class="text-center">
                        {{ tableData.footer[6] }}
                      </th>
                    </tr>
                  </tfoot>
                </template>
              </v-simple-table>
            </v-card>

            <v-card
              outlined
              class="text-left mt-6"
              v-if="session.bilan"
            >
              <v-card-subtitle class="d-block grey darken-2 white--text px-4 py-2">
                <h3>Questionnaire de satisfaction</h3>
              </v-card-subtitle>

              <v-simple-table>
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td class="text-left">
                        1. Est-ce que ce programme DPC a répondu à vos attentes ?
                      </td>
                      <td class="text-left">
                        <v-rating
                          readonly
                          v-model="session.bilan.q1"
                          background-color="grey"
                          :color="colorRating[session.bilan.q1 - 1]"
                          length="10"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td class="text-left">
                        2. Que pensez-vous de l’ergonomie de la plateforme de saisie ?
                      </td>
                      <td class="text-left">
                        <v-rating
                          readonly
                          v-model="session.bilan.q2"
                          background-color="grey"
                          :color="colorRating[session.bilan.q2 - 1]"
                          length="10"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td class="text-left">
                        3. Avez-vous été satisfait de l’apport cognitif de ce programme DPC ?
                      </td>
                      <td class="text-left">
                        <v-rating
                          readonly
                          v-model="session.bilan.q3"
                          background-color="grey"
                          :color="colorRating[session.bilan.q3 - 1]"
                          length="10"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td class="text-left">
                        4. Pensez-vous pouvoir en tirer des apports concrets à mettre en œuvre ?
                      </td>
                      <td class="text-left">
                        <v-rating
                          readonly
                          v-model="session.bilan.q4"
                          background-color="grey"
                          :color="colorRating[session.bilan.q4 - 1]"
                          length="10"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td class="text-left">
                        5. Quelle note globale donnez-vous à ce programme DPC ?
                      </td>
                      <td class="text-left">
                        <v-rating
                          readonly
                          v-model="session.bilan.q5"
                          background-color="grey"
                          :color="colorRating[session.bilan.q5 - 1]"
                          length="10"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td class="text-left">
                        6. Avez-vous des commentaires et/ou suggestions ?
                      </td>
                      <td class="text-left">
                        <div
                          class="ml-3"
                          v-html="session.bilan.q6"
                        />
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </template>

    <v-card-text v-if="loading || !session.dpc">
      <v-icon
        color="primary"
        large
      >
        mdi-loading mdi-spin
      </v-icon>
    </v-card-text>

    <v-card-actions class="my-6">
      <v-spacer />
      <v-btn
        color="primary"
        dark
        depressed
        @click="retour"
      >
        <v-icon class="mr-1">
          mdi-chevron-left
        </v-icon>
        Retour
      </v-btn>
      <v-btn
        color="warning"
        dark
        depressed
        @click="saveTable"
        v-if="!loading && session.dpc"
      >
        <v-icon class="mr-1">
          mdi-file-pdf-outline
        </v-icon>
        Tableau récapitulatif
      </v-btn>
      <v-btn
        color="blue-grey darken-2"
        dark
        depressed
        @click="saveConnectionLogs"
        v-if="!loading && session.dpc"
      >
        <v-icon class="mr-1">
          mdi-calendar-clock
        </v-icon>
        Télécharger le journal de connexions
      </v-btn>
      <v-spacer />
    </v-card-actions>
  </v-card>
</template>

<script>
import api from './api'

import jsPDF from 'jspdf'
import 'jspdf-autotable'

export default {
  data() {
    return {
      loading: false,
      session: {},
      colorRating: [
        'red',
        'red',
        'red',
        'orange',
        'orange',
        'orange',
        'orange',
        'green',
        'green',
        'green'
      ]
    };
  },
  computed: {
    tableData() {

      let table = {};
      table.header = ['Début', 'Fin', 'Section', 'No. de questions', 'Bonnes réponses', '% Bonnes réponses', 'Degré de certitude'];

      let data = [];
      let totalQuestions = 0;
      let totalScore = 0;
      let counter = 0;
      let totalEvaluation = 0;

      this.session.reponses.forEach(function (element) {
        if (element.maxscore > 0) {
          counter++;
          totalQuestions = totalQuestions + element.maxscore;
          totalScore = totalScore + element.score;
          totalEvaluation = totalEvaluation + element.evaluation;
          data.push([
            element.debut,
            element.fin,
            element.titre,
            element.maxscore,
            element.score,
            Math.round(element.score / element.maxscore * 100) + '%',
            element.evaluation + '%',
          ]);
        } else {
          // counter++;
          data.push([
            element.debut,
            element.fin,
            element.titre,
            '',
            '',
            '',
            '',
          ]);
        }
      });

      if (counter > 0) {
        table.footer = [
          '',
          '',
          '',
          totalQuestions,
          totalScore,
          Math.round((totalScore / totalQuestions) * 100) + '%',
          Math.round(totalEvaluation / counter) + '%'
        ];
      } else {
        table.footer = [
          '',
          '',
          '',
          '',
          '',
          '',
          ''
        ];
      }

      table.data = data;

      return table;
    },
    tableDataRecap() {

      let table = {};
      table.header = ['Etape', 'Début', 'Fin'];

      let data = [];

      this.session.recap.etapes.forEach(function (element) {
        data.push([
          element.titre,
          element.created_at,
          element.updated_at,
        ]);
      });

      table.data = data;

      return table;
    },
  },
  methods: {

    // Refresh view
    refresh() {
      this.getData(this.$route.params.id);
    },

    // Get Session Data
    getData(id) {

      this.loading = true;

      api.getData(id)
        .then(response => {

          this.loading = false;

          if (response.data.session) {
            this.session = response.data.session;
          } else {
            this.retour();
          }

        })
        .catch(error => {

          this.loading = false;
          if (error.response) {
            // client received an error response (5xx, 4xx)
            if (error.response.status === 401) {
              this.$store.dispatch('auth/logoutUser');
              this.$store.dispatch('overlay/setMessages', [{ type: 'error', message: 'Votre session a expirée.' }]);
            } else {
              this.$store.dispatch('overlay/setMessages', [{ type: 'error', message: 'Une erreur s\'est produite. Merci de réessayer.' }]);
            }
          } else if (error.request) {
            // client never received a response, or request never left
            this.$store.dispatch('overlay/setMessages', [{ type: 'error', message: 'Erreur de connexion. Merci de réessayer.' }]);
          } else {
            // anything else
            this.$store.dispatch('overlay/setMessages', [{ type: 'error', message: 'Une erreur s\'est produite. Merci de réessayer.' }]);
          }

          this.retour();

        });

    },

    saveTable() {

      const doc = new jsPDF('l', 'pt', 'A4')

      doc.setFontSize('16');
      doc.text('DPC : ' + this.session.dpc.titre, 40, 50);

      doc.setFontSize('12');
      doc.text(this.session.user.prenom + ' ' + this.session.user.nom, 40, 70);

      doc.autoTable({
        head: [this.tableDataRecap.header],
        body: this.tableDataRecap.data,
        margin: { top: 85 },
        styles: {
          halign: 'center',
        },
        columnStyles: {
          0: { halign: 'left' },
          1: { halign: 'center' },
          2: { halign: 'center' },
        },
      })

      doc.autoTable({
        head: [this.tableData.header],
        foot: [this.tableData.footer],
        body: this.tableData.data,
        styles: {
          halign: 'center',
        },
        columnStyles: {
          0: { halign: 'left' },
          1: { halign: 'center' },
          2: { halign: 'center' },
          3: { halign: 'center' },
          4: { halign: 'center' },
          5: { halign: 'center' },
          6: { halign: 'center' },
        },
      })

      doc.save('tableau-recapitulatif.pdf')

    },

    saveConnectionLogs() {
      window.open(process.env.VUE_APP_URL + '/admin/sessions/' + this.$route.params.id + '/logs', '_blank');
    },

    viewUser(id) {
      this.$router.push({ name: 'UtilisateurView', params: { id: id } })
    },
    retour() {
      this.$router.go(-1)
    },
  },
  mounted() {
    this.refresh();
  },
};

</script>

<style lang="scss" scoped>
.v-card.dpc {
  text-align: left;

  .v-image {
    .container {
      position: absolute;
      left: 0;
      bottom: 0;
      background: rgb(0, 0, 0, 0.6);
      color: #FFFFFF;

      h3 {
        font-size: 1.2rem;
        line-height: normal;
        font-weight: bold;
        display: block;
      }
    }
  }
}
</style>
