import Api      from "@/apis/Api.js";
import Csrf     from "@/apis/Csrf"

export default {
    
  // Get list of specialites and list of roles
  async getParams(){
    await Csrf.getCookie();
    return Api.post(`/admin/users/params`);
  },

  // Get list of users
  async getUsers(options){
    await Csrf.getCookie();
    return Api.post(`/admin/users/index`,options);
  },

  
  // Delete user
  async delete(id,permanent){
    await Csrf.getCookie();
    if (permanent){
      return Api.post(`/admin/users/user/delete`,{'id':id});
    } else {
      return Api.post(`/admin/users/user/trash`,{'id':id});
    }
  },

  // Restore user
  async restore(id){
    await Csrf.getCookie();
    return Api.post(`/admin/users/user/restore`,{'id':id});
  },

  // Change user state
  async changeState(id,state){
    await Csrf.getCookie();
    return Api.post(`/admin/users/user/changeState`,{'id':id,'state':state});
  },

  // Change user state
  async sendVerifyLink(id){
    await Csrf.getCookie();
    return Api.post(`/admin/users/user/sendVerifyLink`,{'id':id});
  },

}