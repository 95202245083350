<template>
  <v-app>
    <router-view />
    <overlayComponent />
  </v-app>
</template>

<script>
import overlayComponent  from './components/overlay/Overlay.vue'

export default {
  components: {
    overlayComponent
  },
  watch: {
      '$route' (to) {
        document.title = to.meta.title || 'Agence CCC'
      }
  },
}
</script>

<style>
body{
  color: #000000;
}
.v-btn {
  text-transform: none;
}
</style>
