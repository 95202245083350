var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-account-lock-outline ")]),_vm._v(" Groupes / Permissions "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""}},[(_vm.loading)?_c('v-icon',[_vm._v(" mdi-loading mdi-spin ")]):_c('v-icon',{on:{"click":_vm.refresh}},[_vm._v(" mdi-refresh ")])],1)],1),_c('v-data-table',{attrs:{"page":_vm.options.page,"page-count":_vm.pages,"headers":_vm.headers,"items":_vm.roles,"options":_vm.options,"server-items-length":_vm.total,"loading":_vm.loading,"multi-sort":_vm.options.multiSort,"must-sort":_vm.options.mustSort,"items-per-page":_vm.options.itemsPerPage,"footer-props":{
      'items-per-page-options':[ 5, 10, 20, 50, 100 ]
    }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item",fn:function(row){return [_c('tr',{key:row.item.id},[_c('td',{staticClass:"text-left text-no-wrap"},[_vm._v(" "+_vm._s(row.item.name)+" ")]),_c('td',{staticClass:"text-left py-2"},_vm._l((row.item.permissions),function(permission){return _c('v-chip',{key:permission,staticClass:"ma-1",attrs:{"color":"gray","text-color":"white","small":"","dark":""}},[_vm._v(" "+_vm._s(permission)+" ")])}),1),_c('td',{staticClass:"text-right text-no-wrap"},[(row.item.name != 'Super Admin' && _vm.can('admin_roles_edit'))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"fab":"","dark":"","x-small":"","color":"primary","depressed":""},on:{"click":function($event){return _vm.editGroup(row.item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v("Modifier")])]):_vm._e(),(row.item.name != 'Super Admin' && _vm.can('admin_roles_delete'))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"fab":"","dark":"","x-small":"","color":"error","depressed":""},on:{"click":function($event){return _vm.deleteGroup(row.item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-trash-can-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("Supprimer")])]):_vm._e()],1)])]}}])}),_c('v-card-actions',{staticClass:"pa-5"},[_c('v-spacer'),(_vm.can('admin_roles_add'))?_c('v-btn',{attrs:{"color":"primary","dark":"","depressed":""},on:{"click":_vm.addGroup}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v(" mdi-plus ")]),_vm._v(" Ajouter un groupe ")],1):_vm._e(),_c('v-spacer')],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.dialogTitle))])]),_c('v-card-text',[_c('v-form',{ref:"form"},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Nom du groupe *","rules":_vm.nomRules,"required":""},model:{value:(_vm.formGroupe.nom),callback:function ($$v) {_vm.$set(_vm.formGroupe, "nom", $$v)},expression:"formGroupe.nom"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"items":_vm.permissions,"label":"Permissions","multiple":"","clearable":"","small-chips":"","deletable-chips":""},model:{value:(_vm.formGroupe.permissions),callback:function ($$v) {_vm.$set(_vm.formGroupe, "permissions", $$v)},expression:"formGroupe.permissions"}})],1)],1)],1),_c('small',[_vm._v("* Champs obligatoires")])],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.cancel}},[_vm._v(" Annuler ")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.save}},[_vm._v(" Enregistrer ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }