import Api      from "@/apis/Api.js";
import Csrf     from "@/apis/Csrf"

export default {
    
    async register(form){
        await Csrf.getCookie();

        return Api.post(`/admin/user/register`,form);
    },

    async login(form){
        await Csrf.getCookie();

        return Api.post(`/admin/user/login`,{
            'email':form.email,
            'password':form.password,
            'g-recaptcha-response':form.recaptcha
        });
    },

    async requestReset(form){
        await Csrf.getCookie();

        return Api.post(`/admin/reset-password`,{
            'email':form.email,
            'g-recaptcha-response':form.recaptcha
        });
    },

    async resetPassword(form){
        await Csrf.getCookie();

        return Api.post(`/admin/reset/password`,{
            'email':form.email,
            'password':form.password,
            'password_confirmation':form.password_confirmation,
            'g-recaptcha-response':form.recaptcha,
            'token':form.token,
        });
    },

    async logout(){
        await Csrf.getCookie();

        return Api.get(`/admin/user/logout`);
    },

    async current(){
        await Csrf.getCookie();

        return Api.get(`/admin/user/current`);
    },

}