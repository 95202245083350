<template>
  <v-card
    outlined
  >
    <v-card-title>
      <v-icon
        class="mr-2"
      >
        mdi-school-outline
      </v-icon> 
      Sessions DPC
      <v-spacer />

      <v-btn icon>
        <v-icon v-if="loading">
          mdi-loading mdi-spin
        </v-icon>
        <v-icon 
          v-else 
          @click="refresh"
        >
          mdi-refresh
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-container>
      <v-row>
        <v-col class="col-9">
          <v-text-field
            v-model="options.search"
            append-icon="mdi-magnify"
            label="Search"
            clearable
          />
        </v-col>
        <v-col class="col-3">
          <v-switch
            color="red"
            hide-details
            v-model="options.supprime"
            flat
            inset
            label="Supprimé"
          />
        </v-col>
        <v-col class="col-6">
          <v-autocomplete
            :items="dpc"
            v-model="options.dpc"
            label="DPC"
            multiple
            clearable
            small-chips
            deletable-chips
          />
        </v-col>
        <v-col class="col-3">
          <v-autocomplete
            :items="dpcsession"
            v-model="options.dpcsession"
            label="No. Session"
            multiple
            clearable
            small-chips
            deletable-chips
          />
        </v-col>
        <v-col class="col-3">
          <v-select
            :items="listeSelectStatut"
            v-model="options.statut"
            label="Statut"
          />
        </v-col>
      </v-row>
    </v-container>

    <v-data-table
      :page="options.page"
      :page-count="pages"
      :headers="headers"
      :items="liste"
      :options.sync="options"
      :server-items-length="total"
      :loading="loading"
      :multi-sort="options.multiSort"
      :must-sort="options.mustSort"
      :items-per-page="options.itemsPerPage"
      :footer-props="{
        'items-per-page-options':[ 5, 10, 20, 50, 100 ]
      }"
    >
      <template v-slot:item="row">
        <tr
          :key="row.item.id"
        >
          <td class="text-left">
            {{ row.item.id }}
          </td>
          <td class="text-center">
            {{ row.item.debut }}
          </td>
          <td class="text-center">
            {{ row.item.maj }}
          </td>
          <td class="text-left">
            <a 
              v-if="can('admin_users_access')" 
              @click.prevent="viewUser(row.item.user_id)"
            >{{ row.item.user }}</a>
            <span v-else>{{ row.item.user }}</span>
          </td>
          <td class="text-left">
            <a 
              v-if="can('admin_dpc_access')" 
              @click.prevent="viewDpc(row.item.dpc_id)"
            >{{ row.item.dpc }}</a>
            <span v-else>{{ row.item.dpc }}</span>
          </td>
          <td class="text-left">
            {{ row.item.dpcsession }}
          </td>
          <td class="text-center pa-3">
            <v-progress-circular
              :size="75"
              :width="15"
              :value="row.item.progress"
              color="green"
            >
              {{ row.item.progress }}%
            </v-progress-circular>
          </td>
          <td class="text-left">
            {{ row.item.current }}
          </td>
          <td class="text-right text-no-wrap">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn 
                  fab 
                  dark 
                  x-small 
                  color="primary"
                  depressed
                  class="mx-1"
                  @click="viewSession(row.item.id)"
                  v-if="!row.item.deleted"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon dark>
                    mdi-school-outline
                  </v-icon>
                </v-btn>
              </template>
              <span>Afficher les infos</span>
            </v-tooltip>       
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn 
                  fab 
                  dark 
                  x-small 
                  color="warning"
                  depressed
                  class="mx-1"
                  v-if="row.item.delayed && can('admin_sessions_edit')"
                  v-bind="attrs"
                  v-on="on"
                  @click="disableDelay(row.item)"
                >
                  <v-icon dark>
                    mdi-clock
                  </v-icon>
                </v-btn>     
              </template>
              <span>Désactiver le délai d'attente ({{ row.item.delayedinfo }})</span>
            </v-tooltip>       
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn 
                  fab 
                  dark 
                  x-small 
                  color="success"
                  depressed
                  class="mx-1"
                  v-if="row.item.deleted && can('admin_sessions_delete')"
                  @click="restoreSession(row.item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon dark>
                    mdi-delete-restore
                  </v-icon>
                </v-btn>
              </template>
              <span>Restaurer cette session</span>
            </v-tooltip>       
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn 
                  fab 
                  dark 
                  x-small 
                  color="error"
                  depressed
                  class="mx-1"
                  v-if="can('admin_sessions_delete')"
                  @click="deleteSession(row.item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon dark>
                    mdi-trash-can-outline
                  </v-icon>
                </v-btn>
              </template>
              <span>Supprimer cette session</span>
            </v-tooltip>       
          </td>
        </tr>
      </template>      
    </v-data-table>
  </v-card>
</template>

<script>
import api from './api'

export default {
  data() {
    return {
      total: 0,
      pages: 0,
      liste: [],
      listeSelectStatut: [
        { text: 'Tout afficher', value:null },
        { text: 'En cours', value:1 },
        { text: 'Terminé', value:2 },
      ],
      dpc: [],
      dpcsession: [],
      loading: false,
      options: {},
      headers: [
        { text: "#", value: "id", sortable:true },
        { text: "Début", align: 'center',value: "created_at", sortable:true },
        { text: "MAJ", align: 'center',value: "updated_at", sortable:true },
        { text: "Utilisateur", value: "username", sortable:false },
        { text: "DPC", value: null, sortable: false },
        { text: "Session", value: null, sortable: false },
        { text: "Progression", align: 'center', value: null, sortable: false },
        { text: "Etape actuelle", value: null, sortable: false },
        { text: "", value: null, sortable: false },
      ],
    };
  },
  watch: {
    options: {
      handler(){
        this.getListe();
      },
      deep: true
    }
  },
  methods: {
    // Get list themes
    getParams(){

      api.getParams()
      .then( response => {

        if (response.data){
          this.dpc = response.data.dpc;
          this.dpcsession = response.data.dpcsession;
        }

      })
      .catch(error => {

        if (error.response) {
          // client received an error response (5xx, 4xx)
          if (error.response.status === 401){
            this.$store.dispatch('auth/logoutUser');
            this.$store.dispatch('overlay/setMessages',[{type:'error',message:'Votre session a expirée.'}]);
          } else {
            this.$store.dispatch('overlay/setMessages',[{type:'error',message:'Une erreur s\'est produite. Merci de réessayer.'}]);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          this.$store.dispatch('overlay/setMessages',[{type:'error',message:'Erreur de connexion. Merci de réessayer.'}]);
        } else {
          // anything else
          this.$store.dispatch('overlay/setMessages',[{type:'error',message:'Une erreur s\'est produite. Merci de réessayer.'}]);
        }

      });

    },
    refresh(){
      // this.$store.dispatch('sessions/init');
      // this.options = this.$store.getters['sessions/getOptions'];
      this.getParams();
      this.getListe();
    },
    // Get list of DPC
    getListe(){

      this.$store.dispatch('sessions/setOptions',this.options);
      this.loading = true;

      api.getListe(this.options)
      .then( response => {

        this.loading = false;
        if (response.data){
          this.liste = response.data.liste;
          this.pages = response.data.pages;
          this.total = response.data.total;
        }

      })
      .catch(error => {

        this.loading = false;
        if (error.response) {
          // client received an error response (5xx, 4xx)
          if (error.response.status === 401){
            this.$store.dispatch('auth/logoutUser');
            this.$store.dispatch('overlay/setMessages',[{type:'error',message:'Votre session a expirée.'}]);
          } else {
            this.$store.dispatch('overlay/setMessages',[{type:'error',message:'Une erreur s\'est produite. Merci de réessayer.'}]);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          this.$store.dispatch('overlay/setMessages',[{type:'error',message:'Erreur de connexion. Merci de réessayer.'}]);
        } else {
          // anything else
          this.$store.dispatch('overlay/setMessages',[{type:'error',message:'Une erreur s\'est produite. Merci de réessayer.'}]);
        }

      });
    },
    viewUser(id){
      this.$router.push({ name: 'UtilisateurView', params: { id: id } })
    },
    viewDpc(id){
      alert('Test : ' + id);
      // this.$router.push({ name: 'DpcView', params: { id: id } })
    },
    viewSession(id){
      this.$router.push({ name: 'SessionView', params: { id: id } })
    },
    restoreSession(item){
      this.$confirm('Restaurer la session de "' + item.user + '"?',{
        buttonTrueText: 'Restaurer',
        buttonFalseText: 'Annuler',
        buttonTrueColor: 'success',
        buttonFalseColor: 'default',
        buttonTrueFlat: true,
        buttonFalseFlat: true,
        color: 'warning',
        icon: 'warning',
        title: 'Attention !',
      }).then(res => {
        if (res){

          this.$store.dispatch('overlay/enable');
          api.restore(item.id)
          .then( () => {
            this.getListe();
            this.$store.dispatch('overlay/disable');
            // this.$store.dispatch('overlay/setMessages',[{type:'success',message:'Session restaurée avec succès.'}]);
          })
          .catch(error => {
            this.$store.dispatch('overlay/disable');
            if (error.response) {
              if (error.response.status === 422){
                this.$store.dispatch('overlay/setMessages',[{type:'warning',message:error.response.data.messages.join('<br>')}]);
              } else if (error.response.status === 401){
                // client received an error response (5xx, 4xx)
                this.$store.dispatch('auth/logoutUser');
                this.$store.dispatch('overlay/setMessages',[{type:'error',message:'Votre session a expirée.'}]);
              } else {
                this.$store.dispatch('overlay/setMessages',[{type:'error',message:'Une erreur s\'est produite. Merci de réessayer.'}]);
              }
            } else if (error.request) {
              // client never received a response, or request never left
              this.$store.dispatch('overlay/setMessages',[{type:'error',message:'Erreur de connexion. Merci de réessayer.'}]);
            } else {
              // anything else
              this.$store.dispatch('overlay/setMessages',[{type:'error',message:'Une erreur s\'est produite. Merci de réessayer.'}]);
            }
          });

        }
      })
    },
    deleteSession(item){
      this.$confirm('Supprimer cette session de "' + item.user + '"?',{
        buttonTrueText: 'Supprimer',
        buttonFalseText: 'Annuler',
        buttonTrueColor: 'error',
        buttonFalseColor: 'default',
        buttonTrueFlat: true,
        buttonFalseFlat: true,
        color: 'warning',
        icon: 'warning',
        title: 'Attention !',
      }).then(res => {
        if (res){
          this.$store.dispatch('overlay/enable');
          api.delete(item.id, item.deleted)
          .then( () => {
            this.getListe();
            this.$store.dispatch('overlay/disable');
          })
          .catch(error => {
            this.$store.dispatch('overlay/disable');
            if (error.response) {
              if (error.response.status === 422){
                this.$store.dispatch('overlay/setMessages',[{type:'warning',message:error.response.data.messages.join('<br>')}]);
              } else if (error.response.status === 401){
                // client received an error response (5xx, 4xx)
                this.$store.dispatch('auth/logoutUser');
                this.$store.dispatch('overlay/setMessages',[{type:'error',message:'Votre session a expirée.'}]);
              } else {
                this.$store.dispatch('overlay/setMessages',[{type:'error',message:'Une erreur s\'est produite. Merci de réessayer.'}]);
              }
            } else if (error.request) {
              // client never received a response, or request never left
              this.$store.dispatch('overlay/setMessages',[{type:'error',message:'Erreur de connexion. Merci de réessayer.'}]);
            } else {
              // anything else
              this.$store.dispatch('overlay/setMessages',[{type:'error',message:'Une erreur s\'est produite. Merci de réessayer.'}]);
            }
          });
        }
      })
    },
    disableDelay(item){
      this.$confirm('Désactiver le délai d\'attente pour "' + item.user + '"?',{
        buttonTrueText: 'Désactiver',
        buttonFalseText: 'Annuler',
        buttonTrueColor: 'error',
        buttonFalseColor: 'default',
        buttonTrueFlat: true,
        buttonFalseFlat: true,
        color: 'warning',
        icon: 'warning',
        title: 'Attention !',
      }).then(res => {
        if (res){
          this.$store.dispatch('overlay/enable');
          api.nodelay(item.id)
          .then( () => {
            this.getListe();
            this.$store.dispatch('overlay/disable');
          })
          .catch(error => {
            this.$store.dispatch('overlay/disable');
            if (error.response) {
              if (error.response.status === 422){
                this.$store.dispatch('overlay/setMessages',[{type:'warning',message:error.response.data.messages.join('<br>')}]);
              } else if (error.response.status === 401){
                // client received an error response (5xx, 4xx)
                this.$store.dispatch('auth/logoutUser');
                this.$store.dispatch('overlay/setMessages',[{type:'error',message:'Votre session a expirée.'}]);
              } else {
                this.$store.dispatch('overlay/setMessages',[{type:'error',message:'Une erreur s\'est produite. Merci de réessayer.'}]);
              }
            } else if (error.request) {
              // client never received a response, or request never left
              this.$store.dispatch('overlay/setMessages',[{type:'error',message:'Erreur de connexion. Merci de réessayer.'}]);
            } else {
              // anything else
              this.$store.dispatch('overlay/setMessages',[{type:'error',message:'Une erreur s\'est produite. Merci de réessayer.'}]);
            }
          });
        }
      })
    },
  },
  beforeMount(){
    this.options = this.$store.getters['sessions/getOptions'];
    this.getParams();
  },
  mounted() {
    this.getListe();
  },
};

</script>
