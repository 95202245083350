import Vue from 'vue'
import Vuex from 'vuex'
import CreatePersistedState from "vuex-persistedstate"

// Import modules
import auth         from "./components/auth/store"
import overlay      from "./components/overlay/store"
import mainMenu     from "./components/mainMenu/store"
import permissions  from "./components/permissions/store"
import utilisateurs from "./components/utilisateurs/list/store"
import dpc          from "./components/dpc/list/store"
import inscriptions from "./components/inscriptions/list/store"
import sessions     from "./components/sessions/list/store"

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
    init({dispatch}) {
      dispatch('auth/init');
      dispatch('overlay/init');
      dispatch('permissions/init');
      dispatch('utilisateurs/init');
      dispatch('dpc/init');
      dispatch('inscriptions/init');
      dispatch('sessions/init');
    },
    scrollToTop(){
      window.scrollTo({
        top: 0,
        left: 0
      });      
    }
  },
  modules: {
    auth,
    overlay,
    mainMenu,
    permissions,
    utilisateurs,
    dpc,
    inscriptions,
    sessions,
  },
  plugins: [
    CreatePersistedState()
  ]
})

export default store;