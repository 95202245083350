import Vue from 'vue'
import VueRouter from 'vue-router'

import store from "./store"

import { AuthRoutes }         from './components/auth/routes'
import { DashboardRoutes }    from './components/dashboard/routes'
import { PermissionsRoutes }  from './components/permissions/routes'
import { UtilisateursRoutes } from './components/utilisateurs/routes'
import { DpcRoutes }          from './components/dpc/routes'
import { InscriptionsRoutes } from './components/inscriptions/routes'
import { SessionsRoutes }     from './components/sessions/routes'

Vue.use(VueRouter)

const routes = [
  {
    path: '/manager',
    name: 'Manager',
    component: () => import("./components/mainLayout/MainLayout.vue"),
    meta: { 
      title: 'Manager',
      requiresAuth: true,
    },
    children: [
      // Module Routes
      ...DashboardRoutes,
      ...PermissionsRoutes,
      ...UtilisateursRoutes,
      ...DpcRoutes,
      ...InscriptionsRoutes,
      ...SessionsRoutes,
    ]
  },
  { 
    path: "*", 
    component: () => import("./components/pageNotFound/PageNotFound.vue"),
    meta: {
      auth: false,
      title: 'Page introuvable'
    }
  },

  // Module Routes
  ...AuthRoutes,

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to,from,next) => {

  if (to.meta.requiresAuth){
    if (store.getters['auth/isLoggedIn']){
      next();
    } else {
      next({
        name: 'Connexion'
      });
    }
  } else if (to.meta.requiresGuest){
    if (store.getters['auth/isLoggedIn']){
      next({
        name: 'Dashboard'
      });
    } else {
      next();
    }
  } else {
    next();
  }

})

export default router
