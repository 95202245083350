import ListeSessions      from './list/Liste.vue'
import SessionView     from './view/SessionView.vue'

export const SessionsRoutes = [

    // List
    {
        path: '/sessions',
        name: 'Sessions',
        component: ListeSessions,
        meta: { 
            title: 'Sessions DPC',
            requiresAuth: true,
        }
    },
    
    // View session
    {
        path: '/sessions/:id',
        name: 'SessionView',
        component: SessionView,
        meta: { 
            title: 'Session DPC',
            requiresAuth: true 
        }
    },

]