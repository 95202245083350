import ListeInscriptions from './list/Liste.vue'

export const InscriptionsRoutes = [

    // Demandes d'inscription
    {
        path: '/inscriptions',
        name: 'InscriptionsDpc',
        component: ListeInscriptions,
        meta: { 
            title: 'Inscriptions en attente de validation',
            requiresAuth: true,
        }
    },
    
]