<template>
  <v-card outlined>
    <v-card-title>
      <v-icon class="mr-2">
        mdi-account-question-outline
      </v-icon>
      Inscriptions en attente de validation
      <v-spacer />

      <v-btn icon>
        <v-icon v-if="loading">
          mdi-loading mdi-spin
        </v-icon>
        <v-icon 
          v-else 
          @click="refresh"
        >
          mdi-refresh
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-container>
      <v-row>
        <v-col class="col-4">
          <v-text-field 
            v-model="options.search" 
            append-icon="mdi-magnify" 
            label="Search" 
            clearable 
          />
        </v-col>
      </v-row>
    </v-container>

    <v-data-table 
      :page="options.page" 
      :page-count="pages" 
      :headers="headers" 
      :items="liste" 
      :options.sync="options"
      :server-items-length="total" 
      :loading="loading" 
      :multi-sort="options.multiSort" 
      :must-sort="options.mustSort"
      :items-per-page="options.itemsPerPage" 
      :footer-props="{
        'items-per-page-options': [5, 10, 20, 50, 100]
      }"
    >
      <template v-slot:item="row">
        <tr :key="row.item.id">
          <td class="text-left">
            {{ row.item.id }}
          </td>
          <td class="text-left">
            {{ row.item.date }}
          </td>
          <td class="text-left">
            <a 
              v-if="can('admin_users_access')" 
              @click.prevent="viewUser(row.item.user_id)"
            >{{ row.item.user }}</a>
            <span v-else>{{ row.item.user }}</span>
          </td>
          <td class="text-left">
            <a 
              v-if="can('admin_dpc_access')" 
              @click.prevent="viewDpc(row.item.dpc_id)"
            >{{ row.item.dpc }}</a>
            <span v-else>{{ row.item.dpc }}</span>
          </td>
          <td class="text-right text-no-wrap">
            <v-tooltip 
              top 
              v-if="can('admin_dpc_inscriptions_edit')"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn 
                  fab 
                  dark 
                  x-small 
                  color="success" 
                  depressed 
                  class="mx-1" 
                  @click="approve(row.item)" 
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon dark>
                    mdi-check
                  </v-icon>
                </v-btn>
              </template>
              <span>Approuver cette demande</span>
            </v-tooltip>
            <v-tooltip 
              top 
              v-if="can('admin_dpc_inscriptions_edit')"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn 
                  fab 
                  dark 
                  x-small 
                  color="warning" 
                  depressed 
                  class="mx-1" 
                  @click="decline(row.item)" 
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon dark>
                    mdi-cancel
                  </v-icon>
                </v-btn>
              </template>
              <span>Décliner cette demande</span>
            </v-tooltip>
            <v-tooltip 
              top 
              v-if="can('admin_dpc_inscriptions_delete')"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn 
                  fab 
                  dark 
                  x-small 
                  color="error" 
                  depressed 
                  class="mx-1" 
                  @click="deleteInscription(row.item)"
                  v-bind="attrs" 
                  v-on="on"
                >
                  <v-icon dark>
                    mdi-trash-can-outline
                  </v-icon>
                </v-btn>
              </template>
              <span>Supprimer cette demande</span>
            </v-tooltip>
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-dialog 
      v-model="dialogApprove" 
      persistent
      width="500"
    >
      <v-card>
        <v-card-title>
          Veuillez saisir le nom de la session
        </v-card-title>
        <v-card-text
          class="pb-0"
        >
          <v-text-field 
            v-model="dpcSession" 
            label="Nom de la session" 
            required 
            pattern="\d{2}\.\d{3}"
            :error-messages="getPatternErrorMessage"
          />
        </v-card-text>
        <v-card-actions
          class="pb-4"
        >
          <v-btn 
            depressed 
            @click="cancelApprove"
          >
            Annuler
          </v-btn>
          <v-btn 
            depressed
            color="success"
            @click="confirmApprove"
          >
            Approuver
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import api from './api'

export default {
  data() {
    return {
      dialogApprove: false,
      dpcSession: '',
      selectedItem: null,
      total: 0,
      pages: 0,
      liste: [],
      loading: false,
      options: {},
      headers: [
        { text: "#", value: "id", sortable: true },
        { text: "Date", value: "created_at", sortable: true },
        { text: "Utilisateur", value: "user", sortable: false },
        { text: "DPC", value: "dpc", sortable: false },
        { text: "", value: null, sortable: false },
      ],
    };
  },
  watch: {
    options: {
      handler() {
        this.getListe();
      },
      deep: true
    }
  },
  methods: {

    refresh() {
      // this.$store.dispatch('inscriptions/init');
      this.options = this.$store.getters['inscriptions/getOptions'];
      this.getListe();
    },

    // Get list of DPC
    getListe() {

      this.$store.dispatch('inscriptions/setOptions', this.options);
      this.loading = true;

      api.getListe(this.options)
        .then(response => {

          this.loading = false;
          if (response.data) {
            this.liste = response.data.liste;
            this.pages = response.data.pages;
            this.total = response.data.total;
          }

        })
        .catch(error => {

          this.loading = false;
          if (error.response) {
            // client received an error response (5xx, 4xx)
            if (error.response.status === 401) {
              this.$store.dispatch('auth/logoutUser');
              this.$store.dispatch('overlay/setMessages', [{ type: 'error', message: 'Votre session a expirée.' }]);
            } else {
              this.$store.dispatch('overlay/setMessages', [{ type: 'error', message: 'Une erreur s\'est produite. Merci de réessayer.' }]);
            }
          } else if (error.request) {
            // client never received a response, or request never left
            this.$store.dispatch('overlay/setMessages', [{ type: 'error', message: 'Erreur de connexion. Merci de réessayer.' }]);
          } else {
            // anything else
            this.$store.dispatch('overlay/setMessages', [{ type: 'error', message: 'Une erreur s\'est produite. Merci de réessayer.' }]);
          }

        });

    },
    viewUser(id) {
      this.$router.push({ name: 'UtilisateurView', params: { id: id } })
    },
    viewDpc(id) {
      alert('Test : ' + id);
      // this.$router.push({ name: 'DpcView', params: { id: id } })
    },

    deleteInscription(item) {
      this.$confirm('Supprimer la demande de ' + item.user + ' ?', {
        buttonTrueText: 'Supprimer',
        buttonFalseText: 'Annuler',
        buttonTrueColor: 'error',
        buttonFalseColor: 'default',
        buttonTrueFlat: true,
        buttonFalseFlat: true,
        color: 'warning',
        icon: 'warning',
        title: 'Attention !',
      }).then(res => {
        if (res) {
          this.$store.dispatch('overlay/enable');
          api.delete(item.id)
            .then(() => {
              this.getListe();
              this.$store.dispatch('overlay/disable');
            })
            .catch(error => {
              this.$store.dispatch('overlay/disable');
              if (error.response) {
                if (error.response.status === 422) {
                  this.$store.dispatch('overlay/setMessages', [{ type: 'warning', message: error.response.data.messages.join('<br>') }]);
                } else if (error.response.status === 401) {
                  // client received an error response (5xx, 4xx)
                  this.$store.dispatch('auth/logoutUser');
                  this.$store.dispatch('overlay/setMessages', [{ type: 'error', message: 'Votre session a expirée.' }]);
                } else {
                  this.$store.dispatch('overlay/setMessages', [{ type: 'error', message: 'Une erreur s\'est produite. Merci de réessayer.' }]);
                }
              } else if (error.request) {
                // client never received a response, or request never left
                this.$store.dispatch('overlay/setMessages', [{ type: 'error', message: 'Erreur de connexion. Merci de réessayer.' }]);
              } else {
                // anything else
                this.$store.dispatch('overlay/setMessages', [{ type: 'error', message: 'Une erreur s\'est produite. Merci de réessayer.' }]);
              }
            });
        }
      })
    },
    cancelApprove() {
      this.dialogApprove = false;
      this.dpcSession = '';
      this.selectedItem = null;
    },
    confirmApprove() {
      const { selectedItem, dpcSession } = this;

      if (dpcSession) {
        this.$store.dispatch('overlay/enable');

        api.approve(selectedItem.id, dpcSession)
          .then(() => {
            this.getListe();
            this.$store.dispatch('overlay/disable');
            this.cancelApprove(); // Reset dialog state
          })
          .catch(error => {
            this.$store.dispatch('overlay/disable');
            if (error.response) {
              if (error.response.status === 422) {
                this.$store.dispatch('overlay/setMessages', [{ type: 'warning', message: error.response.data.messages.join('<br>') }]);
              } else if (error.response.status === 401) {
                // client received an error response (5xx, 4xx)
                this.$store.dispatch('auth/logoutUser');
                this.$store.dispatch('overlay/setMessages', [{ type: 'error', message: 'Votre session a expirée.' }]);
              } else {
                this.$store.dispatch('overlay/setMessages', [{ type: 'error', message: 'Une erreur s\'est produite. Merci de réessayer.' }]);
              }
            } else if (error.request) {
              // client never received a response, or request never left
              this.$store.dispatch('overlay/setMessages', [{ type: 'error', message: 'Erreur de connexion. Merci de réessayer.' }]);
            } else {
              // anything else
              this.$store.dispatch('overlay/setMessages', [{ type: 'error', message: 'Une erreur s\'est produite. Merci de réessayer.' }]);
            }
          });
      }
    },
    approve(item) {
      this.selectedItem = item;
      this.dialogApprove = true;
    },
    decline(item) {
      this.$confirm('Décliner la demande de ' + item.user + ' ?', {
        buttonTrueText: 'Décliner',
        buttonFalseText: 'Annuler',
        buttonTrueColor: 'error',
        buttonFalseColor: 'default',
        buttonTrueFlat: true,
        buttonFalseFlat: true,
        color: 'info',
        icon: 'info',
        title: 'Confirmation',
      }).then(res => {
        if (res) {
          this.$store.dispatch('overlay/enable');
          api.decline(item.id)
            .then(() => {
              this.getListe();
              this.$store.dispatch('overlay/disable');
            })
            .catch(error => {
              this.$store.dispatch('overlay/disable');
              if (error.response) {
                if (error.response.status === 422) {
                  this.$store.dispatch('overlay/setMessages', [{ type: 'warning', message: error.response.data.messages.join('<br>') }]);
                } else if (error.response.status === 401) {
                  // client received an error response (5xx, 4xx)
                  this.$store.dispatch('auth/logoutUser');
                  this.$store.dispatch('overlay/setMessages', [{ type: 'error', message: 'Votre session a expirée.' }]);
                } else {
                  this.$store.dispatch('overlay/setMessages', [{ type: 'error', message: 'Une erreur s\'est produite. Merci de réessayer.' }]);
                }
              } else if (error.request) {
                // client never received a response, or request never left
                this.$store.dispatch('overlay/setMessages', [{ type: 'error', message: 'Erreur de connexion. Merci de réessayer.' }]);
              } else {
                // anything else
                this.$store.dispatch('overlay/setMessages', [{ type: 'error', message: 'Une erreur s\'est produite. Merci de réessayer.' }]);
              }
            });
        }
      })
    },
  },
  beforeMount() {
    this.options = this.$store.getters['inscriptions/getOptions'];
  },
  mounted() {
    this.getListe();
  },
  computed: {
    getPatternErrorMessage() {
      return this.dpcSession && !/\d{2}\.\d{3}/.test(this.dpcSession)
        ? 'Format invalide. Exemple: 23.001'
        : '';
    },
  },
};

</script>
