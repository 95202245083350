import Api      from "@/apis/Api.js";
import Csrf     from "@/apis/Csrf"

export default {
    
  // Get list of themes
  async getParams(){
    await Csrf.getCookie();
    return Api.post(`/admin/dpcs/params`);
  },

  // Get list of dpc
  async getListe(options){
    await Csrf.getCookie();
    return Api.post(`/admin/dpcs/index`,options);
  },

  // Delete dpc
  async delete(id, permanent){
    await Csrf.getCookie();
    if (permanent){
      return Api.post(`/admin/dpcs/dpc/delete`,{'id':id});
    } else {
      return Api.post(`/admin/dpcs/dpc/trash`,{'id':id});
    }
  },

  // Restore dpc
  async restore(id){
    await Csrf.getCookie();
    return Api.post(`/admin/dpcs/dpc/restore`,{'id':id});
  },

  // Change inscription
  async changeInscription(id,state){
    await Csrf.getCookie();
    return Api.post(`/admin/dpcs/dpc/changeInscription`,{'id':id,'state':state});
  },

  // Change user state
  async changeState(id,state){
    await Csrf.getCookie();
    return Api.post(`/admin/dpcs/dpc/changeState`,{'id':id,'state':state});
  },

}