import Api      from "@/apis/Api.js";
import Csrf     from "@/apis/Csrf"

export default {

  // Get list of specialites and list of roles
  async getParams(){
    await Csrf.getCookie();
    return Api.post(`/admin/users/params`);
  },
    
  // Get list of users
  async getUser(id){
    await Csrf.getCookie();
    return Api.post(`/admin/users/user`,{'id':id});
  },

  // Save user
  async saveUser(form){
    await Csrf.getCookie();
    if (form.id == null){
      return Api.post(`/admin/users/user/store`,form);
    } else {
      return Api.post(`/admin/users/user/update`,form);
    }
  },

}