<template>
  <v-card
    outlined
  >
    <v-card-title>
      <v-icon
        class="mr-2"
      >
        mdi-account-box-multiple-outline
      </v-icon> 
      {{ titre }} 
      <v-spacer />
      <span v-if="user">{{ user.prenom }} {{ user.nom }}</span>
    </v-card-title>

    <v-card-text 
      v-if="!showForm" 
      class="text-center"
    >
      <v-icon 
        color="primary" 
        large
      >
        mdi-loading mdi-spin
      </v-icon>
    </v-card-text>

    <v-card-text 
      v-if="showForm" 
      class="text-center"
    >
      <v-container
        fluid
      >
        <v-row style="background:yellow;">
          <v-tabs
            background-color="primary"
            center-active
            dark
            v-model="tab"
          >
            <v-tab>Informations personnelles</v-tab>
            <v-tab>Informations professionnelles</v-tab>
            <v-tab>RGPD</v-tab>
            <v-tab>Administration</v-tab>
          </v-tabs>
        </v-row>
      </v-container>
      <v-form 
        ref="form" 
      >
        <v-tabs-items 
          v-model="tab"
        >
          <v-tab-item
            eager
          >
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-radio-group
                  v-model="user.civilite"
                  row
                  :rules="[
                    v => !!v || 'Ce champ est obligatoire'
                  ]"
                >
                  <template v-slot:label>
                    <div>Civilité *</div>
                  </template>                  
                  <v-radio
                    label="Monsieur"
                    :value="1"
                  />
                  <v-radio
                    label="Madame"
                    :value="2"
                  />
                </v-radio-group>
              </v-col>

              <v-col
                cols="12"
                md="6"
              >
                <v-radio-group
                  v-model="user.titre"
                  row
                  :rules="[
                    v => !!v || 'Ce champ est obligatoire'
                  ]"
                >
                  <template v-slot:label>
                    <div>Titre *</div>
                  </template>                  
                  <v-radio
                    label="Docteur"
                    :value="1"
                  />
                  <v-radio
                    label="Professeur"
                    :value="2"
                  />
                </v-radio-group>
              </v-col>

              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  label="Prénom *"
                  v-model="user.prenom"
                  :rules="[
                    v => !!v || 'Ce champ est obligatoire',
                    v => (v && v.length > 1) || 'Ce champ doit contenir un minimum de 2 caractères',
                  ]"
                  required
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  label="Nom *"
                  v-model="user.nom"
                  :rules="[
                    v => !!v || 'Ce champ est obligatoire',
                    v => (v && v.length > 1) || 'Ce champ doit contenir un minimum de 2 caractères',
                  ]"
                  required
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-radio-group
                  v-model="user.typeadr"
                  row
                  :rules="[
                    v => !!v || 'Ce champ est obligatoire'
                  ]"
                >
                  <template v-slot:label>
                    <div>Type adresse *</div>
                  </template>                  
                  <v-radio
                    label="Cabinet"
                    :value="1"
                  />
                  <v-radio
                    label="Hôpital"
                    :value="2"
                  />
                  <v-radio
                    label="Domicile"
                    :value="3"
                  />
                  <v-radio
                    label="Autre"
                    :value="10"
                  />
                </v-radio-group>
              </v-col>
              <v-col
                cols="12"
                md="6"
                v-if="user.typeadr == 10"
              >
                <v-text-field
                  label="Autre type d'adresse *"
                  v-model="user.typeadrautre"
                  :rules="[
                    v => !!v || 'Ce champ est obligatoire',
                    v => (v && v.length > 1) || 'Ce champ doit contenir un minimum de 2 caractères',
                  ]"
                  required
                  v-if="user.typeadr == 10"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  label="Adresse L1 *"
                  v-model="user.adressel1"
                  :rules="[
                    v => !!v || 'Ce champ est obligatoire',
                    v => (v && v.length >= 2) || 'Ce champ doit contenir un minimum de 2 caractères',
                  ]"
                  required
                />
                <v-text-field
                  label="Adresse L2"
                  v-model="user.adressel2"
                />
                <v-text-field
                  label="Adresse L3"
                  v-model="user.adressel3"
                />
                <v-text-field
                  label="Adresse L4"
                  v-model="user.adressel4"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  label="Boite postale"
                  v-model="user.bp"
                />
                <v-text-field
                  label="Lieu-dit"
                  v-model="user.lieudit"
                />
                <v-text-field
                  label="Code postal *"
                  v-model="user.cp"
                  :rules="[
                    v => !!v || 'Ce champ est obligatoire',
                    v => (v && v.length >= 2) || 'Ce champ doit contenir un minimum de 2 caractères',
                  ]"
                  required
                />
                <v-text-field
                  label="Ville *"
                  v-model="user.ville"
                  :rules="[
                    v => !!v || 'Ce champ est obligatoire',
                    v => (v && v.length >= 2) || 'Ce champ doit contenir un minimum de 2 caractères',
                  ]"
                  required
                />
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item
            eager
          >
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  label="RPPS"
                  v-model="user.rpps"
                  :rules="[
                    v => (!v || (!!v && v.length >= 11)) || 'Ce champ doit contenir un minimum de 11 chiffres',
                    v => (!v || Number.isInteger(Number(v))) || 'Ce champ doit contenir uniquement des chiffres',
                  ]"
                />
              </v-col>

              <v-col
                cols="12"
                md="6"
              >
                <v-autocomplete
                  :items="specialites"
                  v-model="user.specialites"
                  label="Spécialité(s) médicale(s) *"
                  :rules="[
                    v => (v && v.length > 0) || 'Vous devez choisir une ou plusieurs spécialités',
                  ]"
                  required
                  multiple
                  clearable
                  small-chips
                  deletable-chips
                />
              </v-col>

              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  label="Téléphone *"
                  v-model="user.telephone"
                  :rules="[
                    v => !!v || 'Ce champ est obligatoire',
                    v => (v && v.length >= 8) || 'Ce champ doit contenir un minimum de 8 chiffres',
                    v => Number.isInteger(Number(v)) || 'Ce champ doit contenir uniquement des chiffres',
                  ]"
                  required
                />
              </v-col>

              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  label="Portable"
                  v-model="user.portable"
                  :rules="[
                    v => (!v || (!!v && v.length >= 8)) || 'Ce champ doit contenir un minimum de 8 chiffres',
                    v => (!v || Number.isInteger(Number(v))) || 'Ce champ doit contenir uniquement des chiffres',
                  ]"
                />
              </v-col>

              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  label="Télécopie"
                  v-model="user.telecopie"
                  :rules="[
                    v => (!v || (!!v && v.length >= 8)) || 'Ce champ doit contenir un minimum de 8 chiffres',
                    v => (!v || Number.isInteger(Number(v))) || 'Ce champ doit contenir uniquement des chiffres',
                  ]"
                />
              </v-col>

              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  label="Email *"
                  v-model="user.email"
                  required
                  :rules="[
                    v => !!v || 'Ce champ est obligatoire',
                    v => /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(v) || 'Adresse email invalide',
                  ]"
                />
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item
            eager
          >
            <v-row>
              <v-col
                cols="12"
              >
                <v-switch
                  v-model="user.optout"
                  flat
                  label="Ne souhaite pas être informé(e) des actualités et recevoir les offres exclusives des partenaires de l'Agence CCC"
                />
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item
            eager
          >
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-autocomplete
                  :items="roles"
                  v-model="user.roles"
                  label="Groupe(s) *"
                  :rules="[
                    v => (v && v.length > 0) || 'Vous devez choisir au moins un groupe',
                  ]"
                  required
                  multiple
                  clearable
                  small-chips
                  deletable-chips
                  :disabled="!can('admin_users_set_roles')"
                />
              </v-col>
              <v-col
                cols="12"
              >
                <v-switch
                  v-model="user.statut"
                  flat
                  label="Statut"
                />
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </v-form>

      <p class="text-left">
        <small>* Champs obligatoires</small>
      </p>
    </v-card-text>

    <v-card-actions
      class="pa-5"
    >
      <v-spacer />
      <v-btn
        color="primary"
        dark
        depressed
        @click="cancel"
      >
        <v-icon class="mr-1">
          mdi-chevron-left
        </v-icon>
        Annuler
      </v-btn>
      <v-btn
        color="success"
        depressed
        @click="save(false)"
        v-if="showForm"
      >
        <v-icon class="mr-1">
          mdi-content-save-edit-outline
        </v-icon>
        Enregistrer
      </v-btn>
      <v-btn
        color="success"
        depressed
        @click="save(true)"
        v-if="showForm"
      >
        <v-icon class="mr-1">
          mdi-content-save-outline
        </v-icon>
        Enregistrer &amp; fermer
      </v-btn>
      <v-spacer />
    </v-card-actions>
  </v-card>
</template>

<script>
import api from './api'

export default {
  data() {
    return {
      tab: 0,
      roles: [],
      specialites: [],
      loading: false,
      task: 'add',
      user: {},
    };
  },
  computed:{
    showForm(){
      return (this.roles.length > 0 && this.specialites.length > 0 && !this.loading)?true:false;
    },
    typeAdr(){
      return this.user.typeadr;
    },
    titre(){
      return this.user.id?'Modifier utilisateur':'Ajouter utilisateur';
    }
  },
  watch:{
    typeAdr(){
      if (this.typeAdr != 10){
        this.user.typeadrautre = '';
      }
    },
  },
  methods: {

    // Get list of Roles + Specs
    getParams(){

      api.getParams()
      .then( response => {

        if (response.data){
          this.roles = response.data.roles;
          this.specialites = response.data.specialites;
        }

      })
      .catch(error => {
    
        if (error.response) {
          // client received an error response (5xx, 4xx)
          if (error.response.status === 401){
            this.$store.dispatch('auth/logoutUser');
            this.$store.dispatch('overlay/setMessages',[{type:'error',message:'Votre session a expirée.'}]);
          } else {
            this.$store.dispatch('overlay/setMessages',[{type:'error',message:'Une erreur s\'est produite. Merci de réessayer.'}]);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          this.$store.dispatch('overlay/setMessages',[{type:'error',message:'Erreur de connexion. Merci de réessayer.'}]);
        } else {
          // anything else
          this.$store.dispatch('overlay/setMessages',[{type:'error',message:'Une erreur s\'est produite. Merci de réessayer.'}]);
        }

        this.cancel();

      });

    },

    // Get User Data
    getUser(id){

      this.loading = true;

      api.getUser(id)
      .then( response => {

        this.loading = false;
        if (response.data.user){
          this.user = response.data.user;
        } else {
          this.cancel();
        }

      })
      .catch(error => {

        this.loading = false;
        if (error.response) {
          // client received an error response (5xx, 4xx)
          if (error.response.status === 401){
            this.$store.dispatch('auth/logoutUser');
            this.$store.dispatch('overlay/setMessages',[{type:'error',message:'Votre session a expirée.'}]);
          } else {
            this.$store.dispatch('overlay/setMessages',[{type:'error',message:'Une erreur s\'est produite. Merci de réessayer.'}]);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          this.$store.dispatch('overlay/setMessages',[{type:'error',message:'Erreur de connexion. Merci de réessayer.'}]);
        } else {
          // anything else
          this.$store.dispatch('overlay/setMessages',[{type:'error',message:'Une erreur s\'est produite. Merci de réessayer.'}]);
        }

        this.cancel();

      });

    },
    cancel(){
      // this.$router.push({ name: 'Utilisateurs' })
      this.$router.go(-1)
    },
    save($return){
      if (this.$refs.form.validate()){
        
        this.$store.dispatch('overlay/enable');
        api.saveUser(this.user)
        .then( response => {

          this.$store.dispatch('overlay/disable');
          if (response.data.user){
            this.user = response.data.user;
            this.$store.dispatch('overlay/setMessages',[{type:'success',message:'Fiche enregistrée avec succès.'}]);
            if ($return) this.cancel();
          }
          
        })
        .catch(error => {
          this.$store.dispatch('overlay/disable');
          if (error.response) {
            if (error.response.status === 422){
              this.$store.dispatch('overlay/setMessages',[{type:'warning',message:error.response.data.messages.join('<br>')}]);
            } else if (error.response.status === 401){
            // client received an error response (5xx, 4xx)
              this.$store.dispatch('auth/logoutUser');
              this.$store.dispatch('overlay/setMessages',[{type:'error',message:'Votre session a expirée.'}]);
            } else {
              this.$store.dispatch('overlay/setMessages',[{type:'error',message:'Une erreur s\'est produite. Merci de réessayer.'}]);
            }
          } else if (error.request) {
            // client never received a response, or request never left
            this.$store.dispatch('overlay/setMessages',[{type:'error',message:'Erreur de connexion. Merci de réessayer.'}]);
          } else {
            // anything else
            this.$store.dispatch('overlay/setMessages',[{type:'error',message:'Une erreur s\'est produite. Merci de réessayer.'}]);
          }
        });

      } else {
        this.$store.dispatch('overlay/setMessages',[{type:'warning',message:'Merci de vérifier le formulaire.'}]);
      }
    },
  },
  beforeMount(){
    this.getParams();
    if (this.$route.name == 'UtilisateurEdit'){
      this.task = 'edit';
      this.getUser(this.$route.params.id);
    } else {
      this.task = 'add';
      this.user = { // Set default values for new user
        roles:['Medecin'],
        statut:1
      };
    }
  },
};

</script>
