import Permissions from './Permissions.vue'

export const PermissionsRoutes = [

  {
    path: '/permissions',
    name: 'Permissions',
    component: Permissions,
    meta: { 
      title: 'Groupes / Permissions',
      requiresAuth: true,
    }
  },
  
]