import ListeDpc        from './list/Liste.vue'
// import UtilisateurView from './view/UtilisateurView.vue'
// import UtilisateurEdit from './edit/UtilisateurEdit.vue'

export const DpcRoutes = [

    // List DPC
    {
        path: '/dpc',
        name: 'Dpc',
        component: ListeDpc,
        meta: { 
            title: 'DPC',
            requiresAuth: true,
        }
    },
    
    // // Add user
    // {
    //     path: '/utilisateurs/creation',
    //     name: 'UtilisateursAdd',
    //     component: UtilisateurEdit,
    //     meta: { 
    //         title: 'Modifier utilisateur',
    //         requiresAuth: true
    //     }
    // },

    // // View user
    // {
    //     path: '/utilisateurs/:id',
    //     name: 'UtilisateurView',
    //     component: UtilisateurView,
    //     meta: { 
    //         title: 'Afficher utilisateur',
    //         requiresAuth: true 
    //     }
    // },

    // // Edit user
    // {
    //     path: '/utilisateurs/:id/modifier',
    //     name: 'UtilisateurEdit',
    //     component: UtilisateurEdit,
    //     meta: { 
    //         title: 'Modifier utilisateur',
    //         requiresAuth: true 
    //     }
    // },

]